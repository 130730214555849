// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createAd } from '../state/ads';
import AdForm from '../components/AdForm/AdForm';
import type { AdTargetType } from '../types';

type Props = {
  types: AdTargetType[],
  onSubmit: (
    title: string,
    typeId: number,
    url: string,
    imageUrl: string,
    from: string,
    to: string
  ) => void
};

function AdCreateContainer(props: Props) {
  return <AdForm types={props.types} onSubmit={props.onSubmit} />;
}

function mapStateToProps(state) {
  return {
    types: state.adTypes
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onSubmit: createAd }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AdCreateContainer);
