// @flow
import React, { Fragment } from 'react';
import Heading from '../components/Heading/Heading';
import Button from '../components/Button/Button';
import Box from '../components/Box/Box';
import AdCreateContainer from '../containers/AdCreate';

const cancelButton = <Button to="/ads">Cancelar</Button>;

export default function AdCreateRoute() {
  return (
    <Fragment>
      <Heading cta={cancelButton}>Nueva Publicidad</Heading>
      <Box>
        <AdCreateContainer />
      </Box>
    </Fragment>
  );
}
