// @flow
import React, { Fragment } from 'react';
import Heading from '../components/Heading/Heading';
import Box from '../components/Box/Box';
import PageRemoveContainer from '../containers/PageRemove';

export default function PageRemoveRoute() {
  return (
    <Fragment>
      <Heading>Borrar Página</Heading>
      <Box>
        <PageRemoveContainer />
      </Box>
    </Fragment>
  );
}
