// @flow
import React from 'react';
import classnames from 'classnames';
import NavigationActionButton from './NavigationActionButton';
import NavigationButton from './NavigationButton';
import ActionButton from './ActionButton';
import SubmitButton from './SubmitButton';
import './Button.css';

type Props = {
  className?: string,
  children: string,
  size: 'small' | 'medium' | 'large',
  type: 'primary' | 'danger' | 'confirm' | 'cta',
  disabled: boolean,
  to?: string,
  onClick?: () => void
};

export default function Button(props: Props) {
  const className = classnames(
    'Button',
    `Button--${props.type}`,
    `Button--${props.size}`,
    props.className
  );

  if (props.to && props.onClick) {
    // Navigation + action button
    return (
      <NavigationActionButton
        to={props.to}
        onClick={props.onClick}
        className={className}
        disabled={props.disabled}
      >
        {props.children}
      </NavigationActionButton>
    );
  } else if (props.to && !props.onClick) {
    // Navigation only button
    return (
      <NavigationButton
        to={props.to}
        className={className}
        disabled={props.disabled}
      >
        {props.children}
      </NavigationButton>
    );
  } else if (!props.to && props.onClick) {
    // Action only button
    return (
      <ActionButton
        onClick={props.onClick}
        className={className}
        disabled={props.disabled}
      >
        {props.children}
      </ActionButton>
    );
  } else {
    // Regular submit button
    return (
      <SubmitButton className={className} disabled={props.disabled}>
        {props.children}
      </SubmitButton>
    );
  }
}

Button.defaultProps = {
  type: 'cta',
  size: 'medium',
  disabled: false
};
