import AWS from 'aws-sdk';
import { DO_REGION, DO_ACCESS_KEY, DO_SECRET_KEY } from './constants';

// Digital Ocean Spaces Connection
const spacesEndpoint = new AWS.Endpoint(DO_REGION);
const s3 = new AWS.S3({
  endpoint: spacesEndpoint,
  accessKeyId: DO_ACCESS_KEY,
  secretAccessKey: DO_SECRET_KEY
});

export default s3;
