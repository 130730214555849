// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toggleObituariesFilter } from '../state/obituaries';
import ObituariesList from '../components/ObituariesList/ObituariesList';
import type { Props } from '../components/ObituariesList/ObituariesList';

function ObituariesListContainer(props: Props) {
  return (
    <ObituariesList obituaries={props.obituaries} filter={props.filter} onToggle={props.onToggle} />
  );
}

function mapStateToProps(state) {
  return {
    filter: state.obituariesFilter,
    obituaries: state.obituaries.map(obituary => ({
      id: obituary.id,
      name: obituary.name,
      age: obituary.age,
      details: obituary.details,
      service: obituary.service,
      church: obituary.church,
      cemetery: obituary.cemetery,
      home: obituary.home
    }))
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onToggle: toggleObituariesFilter }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ObituariesListContainer);
