// @flow
import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  className: string,
  disabled: boolean,
  children: string,
  to: string,
  onClick: () => {}
};

// @TODO: a button within an anchor tag doesn't sound like a great idea.
// Come back and refactor this!
export default function NavigationActionButton(props: Props) {
  return (
    <Link to={props.to}>
      <button
        type="button"
        className={props.className}
        disabled={props.disabled}
        onClick={props.onClick}
      >
        {props.children}
      </button>
    </Link>
  );
}
