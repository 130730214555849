// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'react-router-redux';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { routerMiddleware } from 'react-router-redux';
import createHistory from 'history/createBrowserHistory';
import thunk from 'redux-thunk';
import get from 'lodash/get';
import rootReducer from './state';
import Routes from './containers/Routes';
import AppContainer from './containers/App';
import ScrollToTop from './containers/ScrollToTop';
import { receiveLogin } from './state/token';
import { getToken, isTokenExpired } from './utils/token';
import { getEnvironment } from './utils/environment';
import { setupLocale } from './utils/dates';
import { unregister } from './utils/worker';
import { API_ROOT_URL } from './utils/constants';

// Apply global styles
import './index.css';

// Set up locale.
setupLocale();

// Instantiate Redux store and set up Redux Dev Tools
const history = createHistory();
const middleware = [thunk, routerMiddleware(history)];
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));

// If there's a token saved to localStorage, make sure it's still
// valid and mark the user as authenticated on the Redux store.
// This avoids having to re-login after refreshing the page or closing the tab.
const token = getToken();

if (!!token && !isTokenExpired(token)) {
  store.dispatch(receiveLogin(token));
}

// Mount the application on a valid DOM node
const mountpoint = document.getElementById('root');

if (!mountpoint) {
  throw new Error(`Can't mount application on the selected node`);
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ScrollToTop>
        <AppContainer>
          <Routes />
        </AppContainer>
      </ScrollToTop>
    </ConnectedRouter>
  </Provider>,
  mountpoint
);

// Removing the call that registers the service worker will prevent new registrations,
// and adding the next line will cause any existing service workers to unregister.
// https://github.com/facebook/create-react-app/issues/2715#issuecomment-313171863
unregister();

// Log bundle information
console.info(`Version: ${get(process.env, 'REACT_APP_VERSION', 'N/A')}`);
console.info(`Environment: ${getEnvironment()}`);
console.info(`API URL: ${API_ROOT_URL}`);
