// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import find from 'lodash/find';
import { updateClassified } from '../state/classifieds';
import ClassifiedForm from '../components/ClassifiedForm/ClassifiedForm';
import type { Match } from 'react-router-dom';
import type { ClassifiedType, ImageType } from '../types';

type Props = {
  classifieds: ClassifiedType[],
  match: Match, // Provided by withRouter
  onRemove: (id: number) => void,
  onSubmit: (
    id: number,
    title: string,
    description: string,
    contact: string,
    images: ImageType[],
    from: string,
    to: string
  ) => void
};

function ClassifiedUpdateContainer(props: Props) {
  const id = Number(props.match.params.id);
  const classified = find(props.classifieds, { id });
  const onSubmit = (title, description, contact, images, from, to) =>
    props.onSubmit(id, title, description, contact, images, from, to);

  return <ClassifiedForm {...classified} onSubmit={onSubmit} />;
}

function mapStateToProps(state) {
  return {
    classifieds: state.classifieds
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onSubmit: updateClassified }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClassifiedUpdateContainer)
);
