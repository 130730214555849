// @flow
import React from 'react';
import classnames from 'classnames';
import Dropzone from 'react-dropzone';
import removeIcon from './rubbish-bin.svg';
import type { ImageType } from '../../types';
import './ImageUpload.css';

type Props = {
  className?: string,
  label: string,
  files: ImageType[],
  onAddFile: (files: ImageType[]) => void,
  onRemoveFile: (index: number) => void
};

export default function ImageUpload(props: Props) {
  const className = classnames('Image-Upload', props.className);

  return (
    <div className={className}>
      <div>
        <label className="List-Form__label">{props.label}</label>
        <Dropzone
          className="Image-Upload__dropzone"
          activeClassName="Image-Upload__dropzone--active"
          onDrop={props.onAddFile}
        >
          Arrastre imágenes hasta esta caja, o haga click aquí para seleccionarlas desde el
          explorador de archivos del sistema.
        </Dropzone>
      </div>

      <ul className="List-Form__fields">
        {props.files.map((file, index) => (
          <li key={index} className="List-Form__field Image-Upload__file">
            <img
              src={file.preview}
              className="Image-Upload__file-preview"
              alt={file.name}
              title={file.name}
            />

            <button className="Image-Upload__file-remove" onClick={() => props.onRemoveFile(index)}>
              <img src={removeIcon} alt="Borrar" title="Borrar" />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

ImageUpload.defaultProps = {
  label: 'Imágenes',
  files: []
};
