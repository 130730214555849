// @flow
import React from 'react';
import './ColorPreview.css';

type Props = {
  color?: string,
  title?: string
};

export default function ColorPreview(props: Props) {
  return (
    <span
      title={props.title}
      className="Color-Preview"
      style={{ backgroundColor: props.color }}
    />
  );
}
