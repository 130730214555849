// @flow
import * as React from 'react';
import './Heading.css';

type Props = {
  children: string,
  cta?: React.Node
};

export default function Heading(props: Props) {
  return (
    <header className="Heading">
      <h2 className="Heading__title">{props.children}</h2>

      {!!props.cta && <div className="Heading__cta">{props.cta}</div>}
    </header>
  );
}

Heading.defaultProps = {
  children: 'Cargando...'
};
