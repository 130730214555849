// @flow
import axios from 'axios';
import { showSpinner, hideSpinner } from './spinner';
import { showAlert } from './alerts';
import { RECEIVE_USER_DETAILS } from './action-types';
import {
  ENDPOINT_USER_DETAILS,
  INTERNAL_SERVER_ERROR
} from '../utils/constants';
import type { UserType, Action, ThunkAction } from '../types';

export function receiveUserDetails(user: UserType): Action {
  return {
    type: RECEIVE_USER_DETAILS,
    reducer: () => user
  };
}

export function getUserDetails(): ThunkAction {
  return dispatch => {
    dispatch(showSpinner());

    axios
      .get(ENDPOINT_USER_DETAILS)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          const user = {
            id: response.data.data.id,
            name: response.data.data.name,
            email: response.data.data.email,
            siteUrl: response.data.data.site.website_url,
            logo: response.data.data.site.logo_url
          };

          // Save token to Redux store
          dispatch(receiveUserDetails(user));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}
