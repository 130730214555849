// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';
import find from 'lodash/find';
import get from 'lodash/get';
import { removeObituary } from '../state/obituaries';
import ConfirmRemove from '../components/ConfirmRemove/ConfirmRemove';
import type { Match } from 'react-router-dom';
import type { ObituaryType } from '../types';

type Props = {
  obituaries: ObituaryType[],
  match: Match, // Provided by withRouter
  push: (path: string) => void,
  onRemove: (id: number) => void
};

function ObituaryRemoveContainer(props: Props) {
  const id = Number(props.match.params.id);
  const obituary = find(props.obituaries, { id });
  const name = get(obituary, 'name');

  return (
    <ConfirmRemove
      onBack={() => props.push(`/obituaries/${id}/edit`)}
      onRemove={() => props.onRemove(id)}
    >
      Aviso Necrológico: <strong>{name}</strong>
    </ConfirmRemove>
  );
}

function mapStateToProps(state) {
  return {
    obituaries: state.obituaries
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onRemove: removeObituary, push }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ObituaryRemoveContainer)
);
