// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getPosts } from '../state/posts';
import PostsList from '../components/PostsList/PostsList';
import type { Props } from '../components/PostsList/PostsList';

function PostsListContainer(props: Props) {
  return <PostsList posts={props.posts} onClick={props.onClick} />;
}

function mapStateToProps(state) {
  return {
    posts: state.posts
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onClick: getPosts }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PostsListContainer);
