// @flow
import React from 'react';
import Button from '../Button/Button';
import './NoMatch.css';

export default function NoMatch() {
  return (
    <div className="No-Match">
      <div className="No-Match__title">
        Error 404: La página a la que estás intentando acceder no existe.
      </div>

      <Button to="/" type="confirm">
        Volver a la página principal
      </Button>
    </div>
  );
}
