// @flow
import React, { Fragment } from 'react';
import Heading from '../components/Heading/Heading';
import Button from '../components/Button/Button';
import Box from '../components/Box/Box';
import RosterCreateContainer from '../containers/RosterCreate';

const cancelButton = <Button to="/roster">Cancelar</Button>;

export default function PageCreateRoute() {
  return (
    <Fragment>
      <Heading cta={cancelButton}>Nuevo Turno</Heading>
      <Box>
        <RosterCreateContainer />
      </Box>
    </Fragment>
  );
}
