// @flow
import React from 'react';
import Video from 'react-youtube';
import { getVideoIdFromUrl, getImageThumbnailUrl } from '../../utils/helpers';
import './Thumbnail.css';

type Props = {
  type: string,
  url: string
};

export default function Thumbnail(props: Props) {
  switch (props.type) {
    case 'image':
      return (
        <a
          className="Thumbnail"
          href={props.url}
          target="blank"
          rel="noopener noreferrer"
        >
          <img
            alt=""
            className="Thumbnail__image"
            src={getImageThumbnailUrl(props.url)}
          />
        </a>
      );

    // Heads up! width=200, height=150, try to keep in sync with stylesheet
    // Not ideal but ¯\_(ツ)_/¯
    case 'video':
      return (
        <span className="Thumbnail Thumbnail--video">
          <Video
            videoId={getVideoIdFromUrl(props.url)}
            opts={{ height: 150, width: 200 }}
          />
        </span>
      );

    default:
      return null;
  }
}
