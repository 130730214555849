// @flow
import uuid from 'uuid/v1';
import { SHOW_ALERT, HIDE_ALERT } from './action-types';
import type { Action, ThunkAction } from '../types';

// Time until the alert automatically closes, in milliseconds
const ALERT_DURATION = 7000;

function _showAlert(id: string, message: string, type: string): Action {
  return {
    type: SHOW_ALERT,
    reducer: state =>
      state.concat({
        id,
        message,
        type
      })
  };
}

export function hideAlert(id: string): Action {
  return {
    type: HIDE_ALERT,
    reducer: state => state.filter(alert => alert.id !== id)
  };
}

export function showAlert(
  message: string,
  type: string,
  autoClose: boolean = true
): ThunkAction {
  return dispatch => {
    const id = uuid();

    dispatch(_showAlert(id, message, type));

    // Close automatically after `ALERT_DURATION` secs
    autoClose && setTimeout(() => dispatch(hideAlert(id)), ALERT_DURATION);
  };
}
