// @flow
import axios from 'axios';
import { showSpinner, hideSpinner } from './spinner';
import { showAlert } from './alerts';
import { ENDPOINT_PHARMACIES, INTERNAL_SERVER_ERROR } from '../utils/constants';
import { RECEIVE_PHARMACIES } from './action-types';
import type { PharmacyType, Action, ThunkAction } from '../types';

function receivePharmacies(pharmacies: PharmacyType[]): Action {
  return {
    type: RECEIVE_PHARMACIES,
    reducer: () => pharmacies
  };
}

export function getPharmacies(): ThunkAction {
  return (dispatch, getState) => {
    dispatch(showSpinner());

    axios
      .get(ENDPOINT_PHARMACIES)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          const pharmacies = response.data.data.map(data => {
            const { created_at, updated_at, ...pharmacy } = data;

            return pharmacy;
          });

          dispatch(receivePharmacies(pharmacies));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}
