// @flow
import axios from 'axios';
import get from 'lodash/get';
import { push } from 'react-router-redux';
import { showSpinner, hideSpinner } from './spinner';
import { showAlert } from './alerts';
import { uploadImages } from './imageUpload';
import { isMockImageFile } from '../utils/helpers';
import { RECEIVE_CLASSIFIEDS, FILTER_CLASSIFIEDS } from './action-types';
import { ENDPOINT_CLASSIFIEDS, INTERNAL_SERVER_ERROR } from '../utils/constants';
import type { ClassifiedType, FilterType, ImageType, Action, ThunkAction } from '../types';

function receiveClassifieds(classifieds: ClassifiedType[]): Action {
  return {
    type: RECEIVE_CLASSIFIEDS,
    reducer: () => classifieds
  };
}

function receiveClassifiedsFilter(filter: FilterType): Action {
  return {
    type: FILTER_CLASSIFIEDS,
    reducer: () => filter
  };
}

export function toggleClassifiedsFilter(): ThunkAction {
  return (dispatch, getState) => {
    const filter = getState().classifiedsFilter === 'all' ? 'active' : 'all';

    dispatch(receiveClassifiedsFilter(filter));
    dispatch(getClassifieds());
  };
}

export function getClassifieds(): ThunkAction {
  return (dispatch, getState) => {
    dispatch(showSpinner());

    const endpoint =
      getState().classifiedsFilter === 'active'
        ? ENDPOINT_CLASSIFIEDS
        : `${ENDPOINT_CLASSIFIEDS}/?all`;

    axios
      .get(endpoint)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          const classifieds = response.data.data.map(classified => ({
            id: classified.id,
            title: classified.title,
            description: classified.description,
            contact: classified.contact,
            imageUrl: classified.image_url,
            from: classified.from,
            to: classified.to
          }));

          dispatch(receiveClassifieds(classifieds));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}

export function createClassified(
  title: string,
  description: string,
  contact: string,
  images: ImageType[],
  from: string,
  to: string
): ThunkAction {
  return (dispatch, getState) => {
    // When invoking a Redux Thunk, `dispatch` returns whatever value you are
    // returning from your Thunk action creator, in this case, a promise.
    const uploadImagePromise = dispatch(uploadImages(images));

    // Returning a promise from a Thunk means we can act when it's resolved.
    // Here we are waiting until all images (in this case, a single one)
    // finishes uploading. If no image gets queued up to upload, then our
    // promise resolves immediately and the processing continues its course.
    uploadImagePromise.then(urls => {
      dispatch(showSpinner());

      const payload = {
        title,
        description,
        contact,
        image_url: get(urls, '[0]', null), // Retrieve URL returned by the CDN.
        from,
        to
      };

      axios
        .post(ENDPOINT_CLASSIFIEDS, payload)
        .then(response => {
          dispatch(hideSpinner());

          if (response.status === 200) {
            dispatch(showAlert('Clasificado creado con éxito!', 'success'));
            dispatch(getClassifieds());
            dispatch(push('/classifieds'));
          } else {
            dispatch(showAlert('Error al intentar crear el clasificado', 'error'));
          }
        })
        .catch((error: TypeError) => {
          dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
          dispatch(hideSpinner());
        });
    });
  };
}

export function updateClassified(
  id: number,
  title: string,
  description: string,
  contact: string,
  images: ImageType[],
  from: string,
  to: string
): ThunkAction {
  return (dispatch, getState) => {
    // Mocked images are those we create from an URL, and we only upload an
    // image if the user has selected it from the file picker (i.e. not a mock).
    const imageToUpload = images.filter(image => !isMockImageFile(image));
    const uploadImagePromise = dispatch(uploadImages(imageToUpload));

    uploadImagePromise.then(urls => {
      dispatch(showSpinner());

      // Retrieve the URL to the original classified image. The `preview`
      // property of a mock image contains the URL to the picture on the CDN.
      const originalImage = get(images, '[0].preview');

      // If a new image has been uploaded,
      // it will override the previous classified image, if any.
      const newImageHasBeenUploaded = imageToUpload.length === 1;

      // Determine if the user has left the original classified image untouched.
      const shouldKeepOriginalImage = imageToUpload.length === 0 && !!originalImage;

      // Here we handle three different scenarios:
      // 1. We've got a new picture.
      // 2. We keep the original picture.
      // 3. We save the classified with no picture attached.
      const nextImageUrl = newImageHasBeenUploaded
        ? get(urls, '[0]') // Get image url from CDN.
        : shouldKeepOriginalImage ? originalImage : null; // null means no image.

      // Finally, hit the API.
      const endpoint = `${ENDPOINT_CLASSIFIEDS}/${id}`;
      const payload = {
        title,
        description,
        contact,
        image_url: nextImageUrl,
        from,
        to
      };

      axios
        .put(endpoint, payload)
        .then(response => {
          dispatch(hideSpinner());

          if (response.status === 200) {
            dispatch(showAlert('Clasificado actualizado con éxito!', 'success'));
            dispatch(getClassifieds());
            dispatch(push('/classifieds'));
          } else {
            dispatch(showAlert('Error al intentar actualizar el clasificado', 'error'));
          }
        })
        .catch((error: TypeError) => {
          dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
          dispatch(hideSpinner());
        });
    });
  };
}

export function removeClassified(id: number): ThunkAction {
  return dispatch => {
    dispatch(showSpinner());

    const endpoint = `${ENDPOINT_CLASSIFIEDS}/${id}`;

    axios
      .delete(endpoint)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          dispatch(showAlert('El clasificado se borró con éxito!', 'success'));
          dispatch(getClassifieds());
          dispatch(push('/classifieds'));
        } else {
          dispatch(showAlert('Error al borrar el clasificado', 'error'));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}
