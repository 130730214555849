// @flow
import React, { Fragment } from 'react';
import Heading from '../components/Heading/Heading';
import Box from '../components/Box/Box';
import PostRemoveContainer from '../containers/PostRemove';

export default function PostRemoveRoute() {
  return (
    <Fragment>
      <Heading>Borrar Noticia</Heading>
      <Box>
        <PostRemoveContainer />
      </Box>
    </Fragment>
  );
}
