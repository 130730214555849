// @flow
import React, { Fragment } from 'react';
import Heading from '../components/Heading/Heading';
import Button from '../components/Button/Button';
import Box from '../components/Box/Box';
import PharmacyRosterDetailsContainer from '../containers/PharmacyRosterDetails';

const createExceptionButton = (
  <Button type="confirm" to="pharmacies/exceptions/create">
    Nueva Excepción
  </Button>
);

export default function PharmacyRosterDetailsRoute() {
  return (
    <Fragment>
      <Heading cta={createExceptionButton}>Calendario de Farmacias de Turno</Heading>
      <Box fullWidth>
        <PharmacyRosterDetailsContainer />
      </Box>
    </Fragment>
  );
}
