// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';
import find from 'lodash/find';
import get from 'lodash/get';
import { removePage } from '../state/pages';
import ConfirmRemove from '../components/ConfirmRemove/ConfirmRemove';
import type { Match } from 'react-router-dom';
import type { PageType } from '../types';

type Props = {
  pages: PageType[],
  match: Match, // Provided by withRouter
  push: (path: string) => void,
  onRemove: (id: number) => void
};

function PageRemoveContainer(props: Props) {
  const slug = props.match.params.slug;
  const page = find(props.pages, { slug });
  const id = get(page, 'id');
  const title = get(page, 'title');

  return (
    <ConfirmRemove
      onBack={() => props.push('/pages')}
      onRemove={() => props.onRemove(id)}
    >
      Página: <strong>{title}</strong>
    </ConfirmRemove>
  );
}

function mapStateToProps(state) {
  return {
    pages: state.pages
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onRemove: removePage, push }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PageRemoveContainer)
);
