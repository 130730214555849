// @flow
import React from 'react';
import LoginContainer from '../containers/Login';

export default function LoginRoute() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        padding: '20px'
      }}
    >
      <LoginContainer />
    </div>
  );
}
