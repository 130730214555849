// @flow
import { showProgress, hideProgress } from './progress';
import s3 from '../utils/aws';
import { getRandomFileName } from '../utils/helpers';
import { DO_BUCKET, DO_ENDPOINT } from '../utils/constants';
import type { ImageType, ThunkAction } from '../types';

/**
 * This Thunk uploads pictures to Cloudinary.
 *
 * @param {File[]} files Array of HTML5 files uploaded using react-dropzone.
 */
export function uploadImages(files: ImageType[]): ThunkAction {
  return dispatch => {
    const requests = files.map(file => {
      const fileName = getRandomFileName(file.name);
      const params = {
        Body: file,
        Bucket: DO_BUCKET,
        Key: fileName
      };

      return new Promise((resolve, reject) => {
        // Sending the file to the Spaces
        s3.putObject(params)
          .on('build', request => {
            request.httpRequest.headers.Host = DO_ENDPOINT;
            request.httpRequest.headers['Content-Length'] = file.size;
            request.httpRequest.headers['Content-Type'] = file.type;
            request.httpRequest.headers['x-amz-acl'] = 'public-read';
          })
          .send(err => {
            if (err) {
              reject(err);
            } else {
              const imageUrl = `${DO_ENDPOINT}/${fileName}`;

              resolve(imageUrl);
            }
          });
      });
    });

    dispatch(showProgress(files.length));

    // Upload all pictures to Digital Ocean Spaces
    // and return an array of the uploaded pictures URLs.
    return Promise.all(requests).then(responses => {
      dispatch(hideProgress());

      return responses;
    });
  };
}
