// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';
import find from 'lodash/find';
import get from 'lodash/get';
import { removeClassified } from '../state/classifieds';
import ConfirmRemove from '../components/ConfirmRemove/ConfirmRemove';
import type { Match } from 'react-router-dom';
import type { ClassifiedType } from '../types';

type Props = {
  classifieds: ClassifiedType[],
  match: Match, // Provided by withRouter
  push: (path: string) => void,
  onRemove: (id: number) => void
};

function ClassifiedRemoveContainer(props: Props) {
  const id = Number(props.match.params.id);
  const classified = find(props.classifieds, { id });
  const title = get(classified, 'title');

  return (
    <ConfirmRemove
      onBack={() => props.push(`/classifieds/${id}/edit`)}
      onRemove={() => props.onRemove(id)}
    >
      Clasificado: <strong>{title}</strong>
    </ConfirmRemove>
  );
}

function mapStateToProps(state) {
  return {
    classifieds: state.classifieds
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onRemove: removeClassified, push }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClassifiedRemoveContainer)
);
