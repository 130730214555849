// @flow
import axios from 'axios';
import { showSpinner, hideSpinner } from './spinner';
import { showAlert } from './alerts';
import { parseDateTimeFromApi } from '../utils/dates';
import { RECEIVE_MESSAGES } from './action-types';
import { ENDPOINT_MESSAGES, INTERNAL_SERVER_ERROR } from '../utils/constants';
import type { CategoryType, Action, ThunkAction } from '../types';

function receiveMessages(categories: CategoryType[]): Action {
  return {
    type: RECEIVE_MESSAGES,
    reducer: () => categories
  };
}

export function getMessages(): ThunkAction {
  return dispatch => {
    dispatch(showSpinner());

    axios
      .get(ENDPOINT_MESSAGES)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          const messages = response.data.data.map(message => ({
              id: message.id,
              from: message.from,
              to: message.to,
              subject: message.subject,
              body: message.body,
              date: parseDateTimeFromApi(message.created_at)
            }));

          dispatch(receiveMessages(messages));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}
