// @flow
import React from 'react';

type Props = {
  className?: string,
  disabled: boolean,
  children: string,
  onClick: () => void
};

export default function ActionButton(props: Props) {
  return (
    <button
      type="button"
      className={props.className}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}
