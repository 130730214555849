// @flow
import axios from 'axios';
import { push } from 'react-router-redux';
import { showSpinner, hideSpinner } from './spinner';
import { showAlert } from './alerts';
import { ENDPOINT_OBITUARIES, INTERNAL_SERVER_ERROR } from '../utils/constants';
import { RECEIVE_OBITUARIES, FILTER_OBITUARIES } from './action-types';
import type { ObituaryType, FilterType, Action, ThunkAction } from '../types';

function receiveObituaries(obituaries: ObituaryType[]): Action {
  return {
    type: RECEIVE_OBITUARIES,
    reducer: () => obituaries
  };
}

function receiveObituariesFilter(filter: FilterType): Action {
  return {
    type: FILTER_OBITUARIES,
    reducer: () => filter
  };
}

export function toggleObituariesFilter(): ThunkAction {
  return (dispatch, getState) => {
    const filter = getState().obituariesFilter === 'all' ? 'active' : 'all';

    dispatch(receiveObituariesFilter(filter));
    dispatch(getObituaries());
  };
}

export function getObituaries(): ThunkAction {
  return (dispatch, getState) => {
    dispatch(showSpinner());

    const endpoint =
      getState().obituariesFilter === 'active'
        ? ENDPOINT_OBITUARIES
        : `${ENDPOINT_OBITUARIES}/?all`;

    axios
      .get(endpoint)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          const obituaries = response.data.data.map(obituary => ({
            id: obituary.id,
            name: obituary.name,
            age: obituary.age,
            details: obituary.details,
            service: obituary.service,
            church: obituary.church,
            cemetery: obituary.cemetery,
            home: obituary.home
          }));

          dispatch(receiveObituaries(obituaries));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}

export function createObituary(
  name: string,
  age: string,
  details: string,
  service: ?string,
  church: string,
  cemetery: string,
  home: string
): ThunkAction {
  return (dispatch, getState) => {
    dispatch(showSpinner());

    const body = {
      name,
      age,
      details,
      service,
      church,
      cemetery,
      home
    };

    axios
      .post(ENDPOINT_OBITUARIES, body)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          dispatch(showAlert('El aviso se creó con éxito', 'success'));
          dispatch(getObituaries());
          dispatch(push('/obituaries'));
        } else {
          dispatch(showAlert('Error al crear el aviso', 'error'));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}

export function updateObituary(
  id: number,
  name: string,
  age: string,
  details: string,
  service: string,
  church: string,
  cemetery: string,
  home: string
): ThunkAction {
  return (dispatch, getState) => {
    dispatch(showSpinner());

    const endpoint = `${ENDPOINT_OBITUARIES}/${id}`;
    const body = {
      name,
      age,
      details,
      service,
      church,
      cemetery,
      home
    };

    axios
      .put(endpoint, body)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          dispatch(showAlert('El aviso se actualizó con éxito', 'success'));
          dispatch(getObituaries());
          dispatch(push('/obituaries'));
        } else {
          dispatch(showAlert('Error al crear el aviso', 'error'));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}

export function removeObituary(id: number): ThunkAction {
  return dispatch => {
    dispatch(showSpinner());

    const endpoint = `${ENDPOINT_OBITUARIES}/${id}`;

    axios
      .delete(endpoint)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          dispatch(showAlert('Aviso borrado con éxito', 'success'));
          dispatch(getObituaries());
          dispatch(push('/obituaries'));
        } else {
          dispatch(showAlert('Error al borrar el aviso', 'error'));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}
