// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createClassified } from '../state/classifieds';
import ClassifiedForm from '../components/ClassifiedForm/ClassifiedForm';

type Props = {
  onSubmit: (name: string) => void
};

function CreateClassifiedContainer(props: Props) {
  return <ClassifiedForm onSubmit={props.onSubmit} />;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onSubmit: createClassified }, dispatch);
}

export default connect(null, mapDispatchToProps)(CreateClassifiedContainer);
