// @flow
import React from 'react';
import Editor from '../Editor/Editor';
import Button from '../Button/Button';
import type { PageType } from '../../types';

type Props = {
  onSubmit: (page: PageType) => void,
  id?: number,
  title?: string,
  slug?: string,
  body?: string
};

type State = {
  title: string,
  slug: string
};

export default class PageForm extends React.Component<Props, State> {
  state = {
    title: this.props.title || '',
    slug: this.props.slug || ''
  };

  // We are using refs to retrieve the body content.
  // See the onSubmit function for more info on this.
  editor = null;

  static defaultProps = {
    body: ''
  };

  componentWillReceiveProps(nextProps: Props) {
    const { title, slug } = nextProps;
    const shouldUpdateState = title && slug;

    // This will also be triggered when a new `body` has been passed in.
    // However we don't keep the body content on the internal component state,
    // instead we assign it to the editor on the render function.
    if (shouldUpdateState) {
      this.setState({
        title,
        slug
      });
    }
  }

  onTitleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      title: event.target.value
    });
  };

  onSlugChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      slug: event.target.value
    });
  };

  onSubmit = () => {
    // Body markup is accessed directly using refs.
    // Keeping the container's state synced with the component's state
    // at all times is expensive in terms of performance.
    const formValues = {
      id: this.props.id,
      title: this.state.title,
      slug: this.state.slug,
      body: this.editor.state.value.toString('html')
    };

    this.props.onSubmit(formValues);
  };

  render() {
    return (
      <form method="POST" className="List-Form">
        <ul className="List-Form__fields">
          <li className="List-Form__field">
            <label className="List-Form__label" htmlFor="title">
              Título
            </label>
            <input
              id="title"
              type="text"
              className="List-Form__input"
              value={this.state.title}
              onChange={this.onTitleChange}
            />
          </li>

          <li className="List-Form__field">
            <label className="List-Form__label" htmlFor="slug">
              Slug
            </label>
            <input
              id="slug"
              type="text"
              className="List-Form__input"
              value={this.state.slug}
              onChange={this.onSlugChange}
            />
          </li>

          <li className="List-Form__field">
            <label className="List-Form__label">Contenido</label>
            <Editor ref={editor => (this.editor = editor)}>
              {this.props.body}
            </Editor>
          </li>

          <li className="List-Form__field">
            <Button type="confirm" onClick={this.onSubmit}>
              Guardar
            </Button>
          </li>
        </ul>
      </form>
    );
  }
}
