// @flow
import React, { Fragment } from 'react';
import Heading from '../components/Heading/Heading';
import Box from '../components/Box/Box';
import CategoryRemove from '../containers/CategoryRemove';

export default function CategoryRemoveRoute() {
  return (
    <Fragment>
      <Heading>Borrar Categoría</Heading>
      <Box>
        <CategoryRemove />
      </Box>
    </Fragment>
  );
}
