// @flow
import Bacon from 'baconjs';
import axios from 'axios';
import moment from 'moment';
import { showSpinner, hideSpinner } from './spinner';
import { showAlert } from './alerts';
import { parseDateTimeFromApi } from '../utils/dates';
import {
  ENDPOINT_PHARMACY_ROSTER,
  ENDPOINT_PHARMACY_ROSTER_ENTRIES,
  ENDPOINT_PHARMACY_ROSTER_EXCEPTIONS,
  INTERNAL_SERVER_ERROR
} from '../utils/constants';
import { RECEIVE_PHARMACY_ROSTER } from './action-types';
import type { PharmacyRosterType, Action, ThunkAction } from '../types';

function receivePharmacyRoster(roster: PharmacyRosterType): Action {
  return {
    type: RECEIVE_PHARMACY_ROSTER,
    reducer: () => roster
  };
}

export function getPharmacyRoster(): ThunkAction {
  return (dispatch, getState) => {
    dispatch(showSpinner());

    const pharmacyRoster$ = Bacon.fromPromise(axios.get(ENDPOINT_PHARMACY_ROSTER))
      .filter(response => response.status === 200)
      .map('.data.data');
    const pharmacyRosterId$ = pharmacyRoster$.map('.id');
    const pharmacyRosterEntries$ = pharmacyRosterId$
      .flatMap(id => Bacon.fromPromise(axios.get(`${ENDPOINT_PHARMACY_ROSTER_ENTRIES}/${id}`)))
      .filter(response => response.status === 200)
      .map(response => {
        return response.data.data.map(entry => ({
          id: entry.id,
          order: entry.order,
          pharmacyName: entry.pharmacy.name,
          pharmacyAddress: entry.pharmacy.address,
          pharmacyPhone: entry.pharmacy.phone
        }));
      });
    const pharmacyRosterExceptions$ = pharmacyRosterId$
      .flatMap(id =>
        Bacon.fromPromise(axios.get(`${ENDPOINT_PHARMACY_ROSTER_EXCEPTIONS}/roster/${id}`))
      )
      .filter(response => response.status === 200)
      .map(response => {
        return response.data.data
          .filter(exception => moment.utc(exception.from).isSameOrAfter(moment()))
          .map(exception => ({
            id: exception.id,
            from: parseDateTimeFromApi(exception.from),
            to: parseDateTimeFromApi(exception.to),
            pharmacyName: exception.pharmacy.name
          }));
      });
    const roster$ = Bacon.combineTemplate({
      id: pharmacyRosterId$,
      description: pharmacyRoster$.map('.description'),
      frequency: pharmacyRoster$.map('.frequency'),
      from: pharmacyRoster$.map('.from'),
      to: pharmacyRoster$.map('.to'),
      entries: pharmacyRosterEntries$,
      exceptions: pharmacyRosterExceptions$
    });

    roster$.onValue((roster: PharmacyRosterType) => {
      dispatch(hideSpinner());
      dispatch(receivePharmacyRoster(roster));
    });

    roster$.endOnError(() => {
      dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
      dispatch(hideSpinner());
    });
  };
}
