// @flow
import { SHOW_SPINNER, HIDE_SPINNER } from './action-types';
import type { Action } from '../types';

export function showSpinner(): Action {
  return {
    type: SHOW_SPINNER,
    reducer: () => true
  };
}

export function hideSpinner(): Action {
  return {
    type: HIDE_SPINNER,
    reducer: () => false
  };
}
