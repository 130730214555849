// @flow
import React, { Fragment } from 'react';
import moment from 'moment';
import get from 'lodash/get';
import uuid from 'uuid/v1';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import Filter from '../Filter/Filter';
import ImageUpload from '../ImageUpload/ImageUpload';
import MediaItems from '../MediaItems/MediaItems';
import Editor from '../Editor/Editor';
import Button from '../Button/Button';
import { parseDateToApi } from '../../utils/dates';
import removeIcon from './remove.svg';
import type { MediaItemType, PostType, CategoryType, ImageType } from '../../types';
import './PostForm.css';

type VideoType = {
  id: string,
  url: string
};

type Props = {
  id?: number,
  title?: string,
  categories: CategoryType[],
  category?: ?CategoryType,
  excerpt?: string,
  body?: string,
  expirationDate: ?string,
  media: MediaItemType[], // Already existing media items (Post update only).
  onRemoveMediaItem?: (postId: number, itemId: number) => void, // Post Update.
  onSubmit: (post: PostType) => void
};

type State = {
  title: string,
  category: ?CategoryType,
  excerpt: string,
  hasExpirationDate: boolean,
  expirationDate: ?moment,
  files: ImageType[],
  videos: VideoType[]
};

export default class PostForm extends React.Component<Props, State> {
  state = {
    title: this.props.title || '',
    category: this.props.category || null,
    hasExpirationDate: !!this.props.expirationDate,
    expirationDate: this.props.expirationDate ? moment(this.props.expirationDate) : null,
    excerpt: this.props.excerpt || '',
    files: [],
    videos: []
  };

  // We are using refs to retrieve the body content.
  // See the onSubmit function for more info on this.
  editor = null;

  static defaultProps = {
    media: [],
    categories: []
  };

  componentWillMount() {
    // Start off with an empty video field
    this.onAddVideo();
  }

  componentWillReceiveProps(nextProps: Props) {
    const { title, category, excerpt, body } = nextProps;
    const shouldUpdateState = !!title || !!category || !!excerpt || !!body;

    // `body` is taken care of in the render method itself.
    if (shouldUpdateState) {
      this.setState({
        title,
        category,
        excerpt
      });
    }
  }

  onTitleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      title: event.target.value
    });
  };

  onCategoryChange = (category: CategoryType) => {
    this.setState({
      category
    });
  };

  onExcerptChange = (event: SyntheticInputEvent<HTMLTextAreaElement>) => {
    this.setState({
      excerpt: event.target.value
    });
  };

  onToggleExpirationDate = () => {
    this.setState(prevState => {
      const nextHasExpirationDate = !prevState.hasExpirationDate;
      const nextExpirationDate = nextHasExpirationDate ? moment().add(7, 'days') : null;

      return {
        hasExpirationDate: nextHasExpirationDate,
        expirationDate: nextExpirationDate
      };
    });
  };

  onExpirationDateChange = (expirationDate: moment) => {
    this.setState({
      expirationDate
    });
  };

  onAddVideo = () => {
    const newVideo = {
      id: uuid(),
      url: ''
    };

    this.setState(prevState => ({
      videos: prevState.videos.concat(newVideo)
    }));
  };

  onChangeVideo = (videoId: string, event: SyntheticInputEvent<HTMLInputElement>) => {
    const nextVideos = this.state.videos.map(video => {
      if (video.id === videoId) {
        video.url = event.target.value;
      }

      return video;
    });

    this.setState({
      videos: nextVideos
    });
  };

  onRemoveVideo = (videoId: string) => {
    this.setState(prevState => ({
      videos: prevState.videos.filter(video => video.id !== videoId)
    }));
  };

  onAddFile = (file: ImageType) => {
    this.setState(prevState => ({
      files: prevState.files.concat(file)
    }));
  };

  onRemoveFile = (fileIndex: number) => {
    this.setState(prevState => ({
      files: prevState.files.filter((item, index) => index !== fileIndex)
    }));
  };

  // This only applies to the "Update Post" flow and is about removing
  // media items that are already attached to a post.
  onRemoveMediaItem = (itemId: number) => {
    const postId = this.props.id;

    this.props.onRemoveMediaItem(postId, itemId);
  };

  onSubmit = () => {
    // Body markup is accessed directly using refs.
    // Keeping the container's state synced with the component's state
    // at all times is expensive in terms of performance.
    const body = this.editor.state.value.toString('html');

    // Filter out empty videos.
    const videos = this.state.videos.filter(video => video.url !== '');

    // Parse the optional expiration date, if any.
    // Otherwise set it to null. Null means the post does not expire.
    const expirationDate =
      this.state.hasExpirationDate && this.state.expirationDate
        ? parseDateToApi(this.state.expirationDate)
        : null;

    // Parse form data before sending it to the API.
    const formValues = {
      title: this.state.title,
      excerpt: this.state.excerpt,
      categoryId: get(this.state, 'category.id'),
      files: this.state.files,
      expirationDate,
      body,
      videos
    };

    if (!!this.props.id) {
      formValues.id = this.props.id;
    }

    this.props.onSubmit(formValues);
  };

  render() {
    return (
      <form method="POST">
        <ul className="List-Form__fields">
          <li className="List-Form__field">
            <label className="List-Form__label" htmlFor="title">
              Título
            </label>
            <input
              id="title"
              type="text"
              className="List-Form__input"
              value={this.state.title}
              onChange={this.onTitleChange}
            />
          </li>

          <li className="List-Form__field">
            <label className="List-Form__label" htmlFor="category">
              Categoría
            </label>
            <Select
              id="category"
              valueKey="id"
              labelKey="name"
              className="List-Form__input"
              placeholder="Seleccione una categoría"
              value={get(this.state, 'category.id')}
              options={this.props.categories}
              onChange={this.onCategoryChange}
            />
          </li>

          <li className="List-Form__field">
            <label className="List-Form__label" htmlFor="excerpt">
              Resumen
            </label>
            <textarea
              id="excerpt"
              className="List-Form__textarea"
              value={this.state.excerpt}
              onChange={this.onExcerptChange}
            />
          </li>

          <li className="List-Form__field">
            <label className="List-Form__label">Cuerpo</label>
            <Editor simple ref={editor => (this.editor = editor)}>
              {this.props.body}
            </Editor>
          </li>

          <li className="List-Form__field">
            <Filter active={this.state.hasExpirationDate} onToggle={this.onToggleExpirationDate}>
              Este es un comunicado con fecha de vencimiento (sólo Pantalla Azul)
            </Filter>

            {this.state.hasExpirationDate && (
              <Fragment>
                <label className="List-Form__label">Fecha de vencimiento</label>
                <DatePicker
                  selected={this.state.expirationDate}
                  onChange={this.onExpirationDateChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={60}
                  dateFormat="LLL"
                  timeCaption="Hora"
                  locale="es-ar"
                />
              </Fragment>
            )}
          </li>

          <li className="List-Form__field Post-Form__media-wrapper">
            {/* Upload Images */}
            <ImageUpload
              files={this.state.files}
              onAddFile={this.onAddFile}
              onRemoveFile={this.onRemoveFile}
            />

            {/* Links to YouTube Videos */}
            <div className="Post-Form__video">
              <label className="List-Form__label">Videos</label>
              {this.state.videos.map(video => (
                <div key={video.id} className="Post-Form__video-wrapper">
                  <input
                    className="Post-Form__video-url"
                    type="text"
                    value={video.url}
                    onChange={event => this.onChangeVideo(video.id, event)}
                  />

                  <button
                    onClick={() => this.onRemoveVideo(video.id)}
                    className="Post-Form__video-remove-button"
                  >
                    <img src={removeIcon} alt="Borrar" className="Post-Form__video-remove" />
                  </button>
                </div>
              ))}

              <Button
                type="primary"
                size="small"
                className="Post-Form__video-add"
                onClick={this.onAddVideo}
              >
                Agregar otro video
              </Button>
            </div>
          </li>

          {/*
           * List of already existing media items (only when editing a Post)
           * that the user can choose to remove.
           */}
          {this.props.media.length > 0 && (
            <li className="List-Form__field">
              <label className="List-Form__label">Adjuntos Existentes</label>
              <MediaItems editable items={this.props.media} onRemove={this.onRemoveMediaItem} />
            </li>
          )}

          <li className="List-Form__field">
            <Button type="confirm" onClick={this.onSubmit}>
              Guardar
            </Button>
          </li>
        </ul>
      </form>
    );
  }
}
