// @flow
import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Button from '../Button/Button';
import { parseDateToApi } from '../../utils/dates';
import './ObituaryForm.css';

type Props = {
  id?: number,
  name?: string,
  age?: string,
  details?: string,
  service?: string,
  church?: string,
  cemetery?: string,
  home?: string,
  onSubmit: (
    name: string,
    age: string,
    details: string,
    service: ?string,
    church: string,
    cemetery: string,
    home: string
  ) => void
};

type State = {
  name: string,
  age: string,
  details: string,
  service: moment,
  serviceDateToBeConfirmed: boolean,
  church: string,
  cemetery: string,
  home: string
};

export default class ObituaryForm extends React.Component<Props, State> {
  state = {
    name: this.props.name || '',
    age: this.props.age || '',
    details: this.props.details || '',
    service: this.props.service ? moment(this.props.service) : moment(),
    serviceDateToBeConfirmed: !this.props.service,
    church: this.props.church || '',
    cemetery: this.props.cemetery || '',
    home: this.props.home || ''
  };

  static defaultProps = {
    service: moment().add(1, 'day')
  };

  componentWillReceiveProps(nextProps: Props) {
    const receivedProps = nextProps.name && nextProps.age;

    if (receivedProps) {
      this.setState({
        name: nextProps.name,
        age: nextProps.age,
        details: nextProps.details,
        service: nextProps.service ? moment(nextProps.service) : moment(),
        serviceDateToBeConfirmed: !nextProps.service,
        church: nextProps.church,
        cemetery: nextProps.cemetery,
        home: nextProps.home
      });
    }
  }

  onNameChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      name: event.target.value
    });
  };

  onAgeChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      age: event.target.value
    });
  };

  onDetailsChange = (event: SyntheticInputEvent<HTMLTextAreaElement>) => {
    this.setState({
      details: event.target.value
    });
  };

  onServiceChange = (service: moment) => {
    this.setState({
      service
    });
  };

  onToBeConfirmedChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      serviceDateToBeConfirmed: event.target.checked
    });
  };

  onCemeteryChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      cemetery: event.target.value
    });
  };

  onChurchChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      church: event.target.value
    });
  };

  onHomeChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      home: event.target.value
    });
  };

  onSubmit = () => {
    const service = this.state.serviceDateToBeConfirmed ? null : parseDateToApi(this.state.service);

    this.props.onSubmit(
      this.state.name,
      this.state.age,
      this.state.details,
      service,
      this.state.church,
      this.state.cemetery,
      this.state.home
    );
  };

  render() {
    return (
      <form method="POST">
        <ul className="List-Form__fields">
          <li className="List-Form__field">
            <label htmlFor="name" className="List-Form__label">
              Nombre y Apellido
            </label>
            <input
              id="name"
              type="text"
              value={this.state.name}
              className="List-Form__input"
              placeholder="Nombre y Apellido del fallecido"
              onChange={this.onNameChange}
            />
          </li>

          <li className="List-Form__field">
            <label htmlFor="age" className="List-Form__label">
              Edad
            </label>
            <input
              id="age"
              type="text"
              value={this.state.age}
              className="List-Form__input"
              placeholder="Edad del fallecido"
              onChange={this.onAgeChange}
            />
          </li>

          <li className="List-Form__field">
            <label htmlFor="details" className="List-Form__label">
              Detalles
            </label>
            <textarea
              id="details"
              className="List-Form__textarea"
              placeholder="Ej: Falleció el 1/1/2018 a las 16:00 hs. a la edad de 100 años"
              value={this.state.details}
              onChange={this.onDetailsChange}
            />
          </li>

          <li className="List-Form__field">
            <label className="List-Form__label">Fecha y hora del oficio religioso</label>
            <DatePicker
              selected={this.state.service}
              onChange={this.onServiceChange}
              disabled={this.state.serviceDateToBeConfirmed}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="LLL"
              timeCaption="Hora"
              locale="es-ar"
            />
            <div className="Obituary-Form__tbc">
              <input
                type="checkbox"
                id="tbc"
                name="tbc"
                onChange={this.onToBeConfirmedChange}
                checked={this.state.serviceDateToBeConfirmed}
              />
              <label htmlFor="tbc">A confirmar</label>
            </div>
          </li>

          <li className="List-Form__field">
            <label htmlFor="church" className="List-Form__label">
              Iglesia
            </label>
            <input
              id="church"
              type="text"
              value={this.state.church}
              className="List-Form__input"
              placeholder="Iglesia"
              onChange={this.onChurchChange}
            />
          </li>

          <li className="List-Form__field">
            <label htmlFor="cemetery" className="List-Form__label">
              Cementerio
            </label>
            <input
              id="cemetery"
              type="text"
              value={this.state.cemetery}
              className="List-Form__input"
              placeholder="Cementerio"
              onChange={this.onCemeteryChange}
            />
          </li>

          <li className="List-Form__field">
            <label htmlFor="home" className="List-Form__label">
              Casa de Sepelios
            </label>
            <input
              id="home"
              type="text"
              value={this.state.home}
              className="List-Form__input"
              placeholder="Casa de Sepelios"
              onChange={this.onHomeChange}
            />
          </li>

          <li className="List-Form__field">
            <Button type="confirm" className="Obituary-Form__button" onClick={this.onSubmit}>
              Guardar
            </Button>

            {this.props.id && (
              <Button
                type="danger"
                className="Obituary-Form__button"
                to={`/obituaries/${this.props.id}/remove`}
              >
                Borrar
              </Button>
            )}
          </li>
        </ul>
      </form>
    );
  }
}
