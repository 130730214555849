// @flow
import React, { Fragment } from 'react';
import Heading from '../components/Heading/Heading';
import Button from '../components/Button/Button';
import Box from '../components/Box/Box';
import AdUpdate from '../containers/AdUpdate';

const cancelButton = <Button to="/ads">Cancelar</Button>;

export default function AdUpdateRoute() {
  return (
    <Fragment>
      <Heading cta={cancelButton}>Actualizar Publicidad</Heading>
      <Box>
        <AdUpdate />
      </Box>
    </Fragment>
  );
}
