// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createCategory } from '../state/categories';
import CategoryForm from '../components/CategoryForm/CategoryForm';

type Props = {
  onSubmit: (name: string) => void
};

function CategoryCreateContainer(props: Props) {
  return <CategoryForm onSubmit={props.onSubmit} />;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onSubmit: createCategory }, dispatch);
}

export default connect(null, mapDispatchToProps)(CategoryCreateContainer);
