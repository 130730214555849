// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';
import find from 'lodash/find';
import get from 'lodash/get';
import { removeRoster } from '../state/roster';
import ConfirmRemove from '../components/ConfirmRemove/ConfirmRemove';
import type { Match } from 'react-router-dom';
import type { RosterType } from '../types';

type Props = {
  roster: RosterType[],
  match: Match, // Provided by withRouter
  push: (path: string) => void,
  onRemove: (id: number) => void
};

function RosterRemoveContainer(props: Props) {
  const id = Number(props.match.params.id);
  const roster = find(props.roster, { id });
  const activity = get(roster, 'activity');

  return (
    <ConfirmRemove
      onBack={() => props.push('/roster')}
      onRemove={() => props.onRemove(id)}
    >
      Turno: <strong>{activity}</strong>
    </ConfirmRemove>
  );
}

function mapStateToProps(state) {
  return {
    roster: state.roster
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onRemove: removeRoster, push }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RosterRemoveContainer)
);
