// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createPage } from '../state/pages';
import PageForm from '../components/PageForm/PageForm';
import type { PageType } from '../types';

type Props = {
  onSubmit: (page: PageType) => void
};

function PageCreateContainer(props: Props) {
  return <PageForm onSubmit={props.onSubmit} />;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onSubmit: createPage }, dispatch);
}

export default connect(null, mapDispatchToProps)(PageCreateContainer);
