// @flow
import axios from 'axios';
import { push } from 'react-router-redux';
import { showSpinner, hideSpinner } from './spinner';
import { showAlert } from './alerts';
import { RECEIVE_CATEGORIES } from './action-types';
import { ENDPOINT_CATEGORIES, INTERNAL_SERVER_ERROR } from '../utils/constants';
import type { CategoryType, Action, ThunkAction } from '../types';

function receiveCategories(categories: CategoryType[]): Action {
  return {
    type: RECEIVE_CATEGORIES,
    reducer: () => categories
  };
}

export function getCategories(): ThunkAction {
  return dispatch => {
    dispatch(showSpinner());

    axios
      .get(ENDPOINT_CATEGORIES)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          const categories = response.data.data.map(category => ({
            id: category.id,
            name: category.name,
            description: category.description,
            color: category.color,
            posts: category.posts_count
          }));

          dispatch(receiveCategories(categories));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}

export function createCategory(
  name: string,
  description: string,
  color: string
): ThunkAction {
  return dispatch => {
    dispatch(showSpinner());

    const payload = { name, description, color };

    axios
      .post(ENDPOINT_CATEGORIES, payload)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          dispatch(showAlert('Categoría creada con éxito!', 'success'));
          dispatch(getCategories());
          dispatch(push('/categories'));
        } else {
          dispatch(showAlert('Error al intentar crear la categoría', 'error'));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}

export function updateCategory(
  id: number,
  name: string,
  description: string,
  color: string
): ThunkAction {
  return dispatch => {
    dispatch(showSpinner());

    const endpoint = `${ENDPOINT_CATEGORIES}/${id}`;
    const payload = { name, description, color };

    axios
      .put(endpoint, payload)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          dispatch(showAlert('Categoría actualizada con éxito!', 'success'));
          dispatch(getCategories());
          dispatch(push('/categories'));
        } else {
          dispatch(showAlert('Error al intentar crear la categoría', 'error'));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}

export function removeCategory(id: number): ThunkAction {
  return dispatch => {
    dispatch(showSpinner());

    const endpoint = `${ENDPOINT_CATEGORIES}/${id}`;

    axios
      .delete(endpoint)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          dispatch(showAlert('Categoría borrada con éxito!', 'success'));
          dispatch(getCategories());
          dispatch(push('/categories'));
        } else {
          dispatch(
            showAlert(
              'No puede borrar una categoría que tiene noticias asociadas.',
              'error'
            )
          );
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}
