// @flow
import React from 'react';
import { connect } from 'react-redux';
import MessagesList from '../components/MessagesList/MessagesList';
import type { Props } from '../components/MessagesList/MessagesList';

function MessagesListContainer(props: Props) {
  return <MessagesList messages={props.messages} />;
}

function mapStateToProps(state) {
  return {
    messages: state.messages
  };
}

export default connect(mapStateToProps)(MessagesListContainer);
