// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toggleClassifiedsFilter } from '../state/classifieds';
import ClassifiedsList from '../components/ClassifiedsList/ClassifiedsList';
import type { Props } from '../components/ClassifiedsList/ClassifiedsList';

function ClassifiedsListContainer(props: Props) {
  return (
    <ClassifiedsList
      classifieds={props.classifieds}
      filter={props.filter}
      onToggle={props.onToggle}
    />
  );
}

function mapStateToProps(state) {
  return {
    filter: state.classifiedsFilter,
    classifieds: state.classifieds
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onToggle: toggleClassifiedsFilter }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(
  ClassifiedsListContainer
);
