// @flow
import decode from 'jwt-decode';

// Save token to localStorage.
export function setToken(token: string) {
  localStorage.setItem('token', token);
}

// Retrieve token from localStorage.
export function getToken(): ?string {
  return localStorage.getItem('token');
}

// Clear user token from localStorage when logging out.
export function clearToken() {
  localStorage.removeItem('token');
}

// Given a valid token, retrieve its expiration date.
function getTokenExpirationDate(token: string): ?Date {
  const decoded = decode(token);

  if (!decoded.exp) {
    return null;
  }

  // The 0 here is the key, which sets the date to the epoch.
  const date = new Date(0);

  date.setUTCSeconds(decoded.exp);

  return date;
}

// Given a valid token, check whether it's already expired.
export function isTokenExpired(token: string): boolean {
  const date = getTokenExpirationDate(token);
  const offsetSeconds = 0;

  if (date === null) {
    return false;
  }

  return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
}
