// @flow
import React from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import menuIcon from './menu.svg';
import './Navigation.css';

const NAVIGATION_OPTIONS = [
  { title: 'Categorías', url: '/categories' },
  { title: 'Noticias', url: '/posts' },
  { title: 'Páginas', url: '/pages' },
  { title: 'Emergencias', url: '/roster' },
  { title: 'Farmacias de Turno', url: '/pharmacies' },
  { title: 'Clasificados', url: '/classifieds' },
  { title: 'Publicidades', url: '/ads' },
  { title: 'Necrológicos', url: '/obituaries' },
  { title: 'Mensajes', url: '/messages' }
  // { title: 'Configuración', url: '/settings' }
];

type Props = {
  onLogout: () => void
};

type State = {
  expanded: boolean
};

export default class Navigation extends React.Component<Props, State> {
  state = {
    expanded: false
  };

  toggleExpanded = () => {
    this.setState(prevState => ({
      expanded: !prevState.expanded
    }));
  };

  render() {
    const listClassName = classnames('Navigation__list', {
      'Navigation__list--expanded': this.state.expanded
    });

    return (
      <nav className="Navigation">
        <img
          src={menuIcon}
          alt="Expandir/Colapsar Menu"
          className="Navigation__toggle"
          onClick={this.toggleExpanded}
        />

        <ul className={listClassName}>
          {NAVIGATION_OPTIONS.map((option, index) => (
            <li key={index}>
              <NavLink
                to={option.url}
                onClick={this.toggleExpanded}
                className="Navigation__list-option"
                activeClassName="Navigation__list-option--active"
              >
                {option.title}
              </NavLink>
            </li>
          ))}

          <li key="analytics">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://analytics.google.com"
              className="Navigation__list-option"
              onClick={this.toggleExpanded}
            >
              Estadísticas
            </a>
          </li>

          <li key="slider">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://slider.freyre.com.ar/"
              className="Navigation__list-option"
              onClick={this.toggleExpanded}
            >
              Pantalla Azul
            </a>
          </li>

          <li key="logout">
            <NavLink
              to="/login"
              onClick={this.props.onLogout}
              className="Navigation__list-option"
              activeClassName="Navigation__list-option--active"
            >
              Cerrar Sesión
            </NavLink>
          </li>
        </ul>
      </nav>
    );
  }
}
