// @flow
import React, { Fragment } from 'react';
import Button from '../components/Button/Button';
import Heading from '../components/Heading/Heading';
import Box from '../components/Box/Box';
import PagesListContainer from '../containers/PagesList';

const createPageButton = (
  <Button type="confirm" to="pages/create">
    Nueva Página
  </Button>
);

export default function PagesListRoute() {
  return (
    <Fragment>
      <Heading cta={createPageButton}>Páginas</Heading>
      <Box fullWidth>
        <PagesListContainer />
      </Box>
    </Fragment>
  );
}
