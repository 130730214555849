// @flow
import React, { Fragment } from 'react';
import Table from '../Table/Table';
import Badge from '../Badge/Badge';
import Center from '../Center/Center';
import MutedText from '../MutedText/MutedText';
import type { PageType } from '../../types';
import './PagesList.css';

export type Props = {
  rootUrl: string,
  pages: PageType[]
};

export default function PagesList(props: Props) {
  const url = `${props.rootUrl}/contenidos/institucional`;
  const rows = props.pages.map(page => ({
    link: `/pages/${page.slug}`,
    cells: [
      page.title,
      <MutedText>{page.slug}</MutedText>,
      <Center>
        <Badge>{page.date}</Badge>
      </Center>
    ]
  }));

  return (
    <Fragment>
      <p className="Pages-List__blurb">
        Una página contiene contenido estático de tipo institucional, de
        marketing o promocional. Si Usted crea una página y usa{' '}
        <strong>institucional</strong> como <em>slug</em>, puede acceder y
        compartir su página mediante la URL{' '}
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>.
      </p>

      <Table
        header={['Título', 'Slug', <Center>Última Actualización</Center>]}
        rows={rows}
      />
    </Fragment>
  );
}

PagesList.defaultProps = {
  rootUrl: 'https://ejemplo.com'
};
