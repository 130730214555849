// @flow
import React from 'react';
import Button from '../Button/Button';
import './RosterForm.css';

type Props = {
  id?: number,
  activity?: string,
  name?: string,
  phone?: string,
  onSubmit: (activity: string, name: string, phone: string) => void
};

type State = {
  activity: string,
  name: string,
  phone: string,
  pristine: boolean
};

export default class Roster extends React.Component<Props, State> {
  state = {
    activity: this.props.activity || '',
    name: this.props.name || '',
    phone: this.props.phone || '',
    pristine: true
  };

  static defaultProps = {
    outdated: false
  };

  onActivityChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      activity: event.target.value,
      pristine: false
    });
  };

  onNameChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      name: event.target.value,
      pristine: false
    });
  };

  onPhoneChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      phone: event.target.value,
      pristine: false
    });
  };

  onSubmit = () => {
    this.props.onSubmit(this.state.activity, this.state.name, this.state.phone);

    this.setState({
      pristine: true
    });
  };

  render() {
    return (
      <form method="POST">
        <ul className="List-Form__fields">
          <li className="List-Form__field">
            <label htmlFor="activity" className="List-Form__label">
              Actividad
            </label>
            <input
              id="activity"
              type="text"
              placeholder="Nombre del servicio"
              className="List-Form__input"
              value={this.state.activity}
              onChange={this.onActivityChange}
            />
          </li>

          <li className="List-Form__field">
            <label htmlFor="name" className="List-Form__label">
              Nombre de la persona o empresa de guardia
            </label>
            <input
              id="name"
              type="text"
              placeholder="Nombre de la persona o empresa de guardia"
              className="List-Form__input"
              value={this.state.name}
              onChange={this.onNameChange}
            />
          </li>

          <li className="List-Form__field">
            <label htmlFor="phone" className="List-Form__label">
              Número de teléfono o dirección
            </label>
            <input
              id="phone"
              type="text"
              placeholder="Número de teléfono o dirección"
              className="List-Form__input"
              value={this.state.phone}
              onChange={this.onPhoneChange}
            />
          </li>

          <li className="List-Form__field">
            <Button
              type="confirm"
              className="Roster-Form__button"
              disabled={this.state.pristine}
              onClick={this.onSubmit}
            >
              Guardar
            </Button>

            {this.props.id && (
              <Button
                type="danger"
                className="Roster-Form__button"
                to={`/roster/${this.props.id}/remove`}
              >
                Borrar
              </Button>
            )}
          </li>
        </ul>
      </form>
    );
  }
}
