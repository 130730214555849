// @flow
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { hideAlert } from '../state/alerts';
import { bootstrap } from '../state/bootstrap';
import { logout } from '../state/token';
import App from '../components/App/App';
import type { Props as ComponentProps } from '../components/App/App';

type Props = ComponentProps & {
  bootstrap: () => void
};

class AppContainer extends React.Component<Props> {
  componentWillMount() {
    this.props.bootstrap();
  }

  render() {
    const { children, ...props } = this.props;

    return <App {...props}>{children}</App>;
  }
}

function mapStateToProps(state) {
  return {
    alerts: state.alerts,
    authenticated: !!state.token,
    loading: state.spinner,
    progress: state.progress
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { bootstrap, hideAlert, onLogout: logout },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppContainer)
);
