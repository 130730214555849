// @flow
import React, { Fragment } from 'react';
import Heading from '../components/Heading/Heading';
import Box from '../components/Box/Box';
import AdRemove from '../containers/AdRemove';

export default function AdRemoveRoute() {
  return (
    <Fragment>
      <Heading>Borrar Publicidad</Heading>
      <Box>
        <AdRemove />
      </Box>
    </Fragment>
  );
}
