// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RosterList from '../components/RosterList/RosterList';
import { updateRoster } from '../state/roster';
import type { RosterType } from '../types';

type Props = {
  entries: RosterType[],
  onUpdate: (id: number, activity: string, name: string, phone: string) => void
};

function RosterListContainer(props: Props) {
  return <RosterList entries={props.entries} onUpdate={props.onUpdate} />;
}

RosterListContainer.defaultProps = {
  entries: []
};

function mapStateToProps(state) {
  return {
    entries: state.roster
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onUpdate: updateRoster }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(
  RosterListContainer
);
