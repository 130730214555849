// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { savePost } from '../state/posts';
import PostForm from '../components/PostForm/PostForm';
import type { PostFormType, CategoryType } from '../types';

type Props = {
  categories: CategoryType[],
  onSubmit: (post: PostFormType) => void
};

function PostCreateContainer(props: Props) {
  return <PostForm {...props} />;
}

function mapStateToProps(state) {
  return {
    categories: state.categories
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onSubmit: savePost }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(
  PostCreateContainer
);
