// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import find from 'lodash/find';
import { updatePage } from '../state/pages';
import PageForm from '../components/PageForm/PageForm';
import type { Match } from 'react-router-dom';
import type { PageType } from '../types';

type Props = {
  pages: PageType[],
  match: Match, // Provided by withRouter
  onSubmit: (page: PageType) => void
};

function PageUpdateContainer(props: Props) {
  const slug = props.match.params.slug;
  const page = find(props.pages, { slug });

  return <PageForm {...page} onSubmit={props.onSubmit} />;
}

function mapStateToProps(state) {
  return {
    pages: state.pages
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onSubmit: updatePage }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PageUpdateContainer)
);
