// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import './Table.css';

type Cell = React.Node;

type Row = {
  cells: Cell[],
  link?: string
};

type Props = {
  header: Cell[],
  rows: Row[]
};

export default function Table(props: Props) {
  return (
    <div className="Table">
      {/* Table Heading */}
      <header className="Table__row">
        {props.header.map((cell, index) => (
          <div className="Table__cell" key={index}>
            {cell}
          </div>
        ))}
      </header>

      {// Table Rows
      props.rows.map((row, index) => {
        const cells = row.cells.map((cell, index) => (
          <div className="Table__cell" key={index}>
            {cell}
          </div>
        ));

        if (row.link) {
          return (
            <Link to={row.link} className="Table__row" key={index}>
              {cells}
            </Link>
          );
        } else {
          return (
            <div className="Table__row" key={index}>
              {cells}
            </div>
          );
        }
      })}
    </div>
  );
}
