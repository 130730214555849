// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createRoster } from '../state/roster';
import RosterForm from '../components/RosterForm/RosterForm';

type Props = {
  onSubmit: (activity: string, name: string, phone: string) => void
};

function RosterCreateContainer(props: Props) {
  return <RosterForm onSubmit={props.onSubmit} />;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onSubmit: createRoster }, dispatch);
}

export default connect(null, mapDispatchToProps)(RosterCreateContainer);
