// @flow
import React, { Fragment } from 'react';
import Button from '../components/Button/Button';
import Heading from '../components/Heading/Heading';
import Box from '../components/Box/Box';
import CategoriesList from '../containers/CategoriesList';

const createCategoryButton = (
  <Button type="confirm" to="categories/create">
    Nueva Categoría
  </Button>
);

export default function CategoriesRoute() {
  return (
    <Fragment>
      <Heading cta={createCategoryButton}>Categorías</Heading>
      <Box fullWidth>
        <CategoriesList />
      </Box>
    </Fragment>
  );
}
