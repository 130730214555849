// @flow
import React from 'react';
import moment from 'moment';
import get from 'lodash/get';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import Button from '../Button/Button';
import ImageUpload from '../ImageUpload/ImageUpload';
import { parseDateToApi } from '../../utils/dates';
import { createMockImageFileFromUrl } from '../../utils/helpers';
import type { AdTargetType, ImageType } from '../../types';
import './AdForm.css';

type Props = {
  types: AdTargetType[],
  onSubmit: (
    title: string,
    typeId: number,
    url: string,
    images: ImageType[],
    from: string,
    to: string
  ) => void,
  id?: number,
  title?: string,
  type?: ?AdTargetType,
  url?: string,
  imageUrl?: string,
  from?: string,
  to?: string
};

type State = {
  title: string,
  url: string,
  type: ?AdTargetType,
  images: ImageType[],
  from: moment,
  to: moment
};

export default class AdForm extends React.Component<Props, State> {
  state = {
    title: this.props.title || '',
    type: this.props.type || null,
    url: this.props.url || '',
    from: this.props.from ? moment(this.props.from) : moment(),
    to: this.props.to ? moment(this.props.to) : moment().add(2, 'weeks'),
    images: this.props.imageUrl
      ? [createMockImageFileFromUrl(this.props.imageUrl)]
      : []
  };

  static defaultProps = {
    types: []
  };

  componentWillReceiveProps(nextProps: Props) {
    const { id, title, type, url, imageUrl, from, to } = nextProps;
    const shouldUpdateState = !!id;

    if (shouldUpdateState) {
      this.setState(prevState => ({
        title: title || prevState.title,
        type: type || prevState.type,
        url: url || prevState.url,
        from: from ? moment(from) : prevState.from,
        to: to ? moment(to) : prevState.to,
        images: imageUrl
          ? [createMockImageFileFromUrl(imageUrl)]
          : prevState.images
      }));
    }
  }

  onTitleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      title: event.target.value
    });
  };

  onTypeChange = (type: AdTargetType) => {
    this.setState({
      type
    });
  };

  onUrlChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      url: event.target.value
    });
  };

  onFromChange = (from: moment) => {
    this.setState({
      from
    });
  };

  onToChange = (to: moment) => {
    this.setState({
      to
    });
  };

  onAddPicture = (images: ImageType[]) => {
    this.setState({
      images
    });
  };

  onRemovePicture = () => {
    this.setState({
      images: []
    });
  };

  onSubmit = () => {
    this.props.onSubmit(
      this.state.title,
      get(this.state, 'type.id', null),
      this.state.url,
      this.state.images,
      parseDateToApi(this.state.from),
      parseDateToApi(this.state.to)
    );
  };

  render() {
    return (
      <form method="POST">
        <ul className="List-Form__fields">
          <li className="List-Form__field">
            <label htmlFor="title" className="List-Form__label">
              Título (sólo para uso interno)
            </label>
            <input
              id="title"
              type="text"
              className="List-Form__input"
              placeholder="Título de la publicidad"
              value={this.state.title}
              onChange={this.onTitleChange}
            />
          </li>

          <li className="List-Form__field">
            <label className="List-Form__label" htmlFor="type">
              Alcance (en qué plataformas es visible la publicidad)
            </label>
            <Select
              id="type"
              valueKey="id"
              labelKey="description"
              className="List-Form__input"
              placeholder="Seleccione el alcance de la publicidad"
              value={get(this.state, 'type.id')}
              options={this.props.types}
              onChange={this.onTypeChange}
            />
          </li>

          <li className="List-Form__field">
            <label htmlFor="url" className="List-Form__label">
              Link (opcional)
            </label>
            <input
              id="url"
              type="text"
              className="List-Form__input"
              placeholder="Link a abrir cuando se hace click en la publicidad"
              value={this.state.url}
              onChange={this.onUrlChange}
            />
          </li>

          <li className="List-Form__field">
            <label className="List-Form__label">
              Fecha de inicio de la publicidad
            </label>
            <DatePicker
              selected={this.state.from}
              onChange={this.onFromChange}
              dateFormat="dddd D [de] MMMM [de] YYYY"
              locale="es-ar"
            />
          </li>

          <li className="List-Form__field">
            <label className="List-Form__label">
              Fecha de finalización de la publicidad
            </label>
            <DatePicker
              selected={this.state.to}
              onChange={this.onToChange}
              dateFormat="dddd D [de] MMMM [de] YYYY"
              locale="es-ar"
            />
          </li>

          <li className="List-Form__field">
            <ImageUpload
              label="Imagen"
              className="Ad-Form__image-wrapper"
              files={this.state.images}
              onAddFile={this.onAddPicture}
              onRemoveFile={this.onRemovePicture}
            />
          </li>

          <li className="List-Form__field">
            <Button
              type="confirm"
              className="Ad-Form__button"
              onClick={this.onSubmit}
            >
              Guardar
            </Button>

            {this.props.id && (
              <Button
                type="danger"
                className="Ad-Form__button"
                to={`/ads/${this.props.id}/remove`}
              >
                Borrar
              </Button>
            )}
          </li>
        </ul>
      </form>
    );
  }
}
