// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createException } from '../state/pharmacyException';
import PharmacyExceptionForm from '../components/PharmacyExceptionForm/PharmacyExceptionForm';
import type { PharmacyType } from '../types';

type Props = {
  pharmacies: PharmacyType[],
  onSubmit: (from: string, to: string, pharmacyId: number) => void
};

function PharmacyExceptionCreateContainer(props: Props) {
  return <PharmacyExceptionForm {...props} />;
}

function mapStateToProps(state) {
  return {
    pharmacies: state.pharmacies
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onSubmit: createException }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyExceptionCreateContainer);
