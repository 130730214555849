// @flow
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import find from 'lodash/find';
import PagePreview from '../components/PagePreview/PagePreview';
import type { Match } from 'react-router-dom';
import type { PageType } from '../types';

type Props = {
  pages: PageType[],
  match: Match // Provided by withRouter
};

function PageDetailsContainer(props: Props) {
  const slug = props.match.params.slug;
  const page = find(props.pages, { slug });

  return <PagePreview {...page} />;
}

function mapStateToProps(state) {
  return {
    pages: state.pages
  };
}

export default withRouter(connect(mapStateToProps)(PageDetailsContainer));
