// @flow
import * as React from 'react';
import './Center.css';

type Props = {
  children?: React.Node
};

export default function Center(props: Props) {
  return <div className="Center">{props.children}</div>;
}
