// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createObituary } from '../state/obituaries';
import ObituaryForm from '../components/ObituaryForm/ObituaryForm';

type Props = {
  onSubmit: (
    name: string,
    age: string,
    details: string,
    service: string,
    church: string,
    cemetery: string,
    home: string
  ) => void
};

function ObituaryCreateContainer(props: Props) {
  return <ObituaryForm onSubmit={props.onSubmit} />;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onSubmit: createObituary }, dispatch);
}

export default connect(null, mapDispatchToProps)(ObituaryCreateContainer);
