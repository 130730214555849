// @flow
import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import PagesList from '../components/PagesList/PagesList';
import type { Props } from '../components/PagesList/PagesList';

function PagesListContainer(props: Props) {
  return <PagesList rootUrl={props.rootUrl} pages={props.pages} />;
}

function mapStateToProps(state) {
  return {
    rootUrl: get(state, 'user.siteUrl'),
    pages: state.pages
  };
}

export default connect(mapStateToProps)(PagesListContainer);
