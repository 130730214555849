// @flow
import React, { Fragment } from 'react';
import Heading from '../components/Heading/Heading';
import Button from '../components/Button/Button';
import Box from '../components/Box/Box';
import ClassifiedUpdate from '../containers/ClassifiedUpdate';

const cancelButton = <Button to="/classifieds">Cancelar</Button>;

export default function ClassifiedUpdateRoute() {
  return (
    <Fragment>
      <Heading cta={cancelButton}>Actualizar Clasificado</Heading>
      <Box>
        <ClassifiedUpdate />
      </Box>
    </Fragment>
  );
}
