// @flow
import axios from 'axios';
import { getRoster } from './roster';
import { getPages } from './pages';
import { getCategories } from './categories';
import { getPosts } from './posts';
import { getObituaries } from './obituaries';
import { getClassifieds } from './classifieds';
import { getUserDetails } from './user';
import { getAds } from './ads';
import { getAdTypes } from './adTypes';
import { getPharmacies } from './pharmacies';
import { getPharmacyRoster } from './pharmacyRoster';
import { getMessages } from './messages';
import type { ThunkAction } from '../types';

/*
 * Application bootstraping logic.
 * Use this function to fulfill all data dependencies.
 */
export function bootstrap(): ThunkAction {
  return (dispatch, getState) => {
    const token = getState().token;

    if (token) {
      // Set the default header which will be sent along with every request.
      axios.defaults.headers.common.authorization = `Bearer ${token}`;

      // Hydrate application state.
      dispatch(getUserDetails());
      dispatch(getRoster());
      dispatch(getPages());
      dispatch(getCategories());
      dispatch(getPosts());
      dispatch(getObituaries());
      dispatch(getClassifieds());
      dispatch(getAds());
      dispatch(getAdTypes());
      dispatch(getPharmacies());
      dispatch(getPharmacyRoster());
      dispatch(getMessages());
    }
  };
}
