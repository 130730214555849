// @flow
import React from 'react';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import './Filter.css';

type Props = {
  children: string,
  active: boolean,
  onToggle: () => void
};

export default function Filter(props: Props) {
  return (
    <div className="Filter">
      <Toggle
        id="toggle"
        defaultChecked={props.active}
        onChange={props.onToggle}
      />

      <label htmlFor="toggle" className="Filter__label">
        {props.children}
      </label>
    </div>
  );
}

Filter.defaultProps = {
  children: 'Mostrar únicamente los elementos recientes/activos'
};
