// @flow
import React, { Fragment } from 'react';
import Filter from '../Filter/Filter';
import Table from '../Table/Table';
import MutedText from '../MutedText/MutedText';
import type { ClassifiedType, FilterType } from '../../types';

export type Props = {
  classifieds: ClassifiedType[],
  filter: FilterType,
  onToggle: () => void
};

export default function ClassifiedsList(props: Props) {
  const rows = props.classifieds.map(classified => ({
    link: `/classifieds/${classified.id}/edit`,
    cells: [classified.title, <MutedText>{classified.description}</MutedText>]
  }));

  return (
    <Fragment>
      <Filter active={props.filter === 'active'} onToggle={props.onToggle}>
        Mostrar únicamente los clasificados activos
      </Filter>

      <Table header={['Título', 'Descripción']} rows={rows} />
    </Fragment>
  );
}
