// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';
import find from 'lodash/find';
import get from 'lodash/get';
import { removePost } from '../state/posts';
import ConfirmRemove from '../components/ConfirmRemove/ConfirmRemove';
import type { Match } from 'react-router-dom';
import type { PostType } from '../types';

type Props = {
  posts: PostType[],
  match: Match, // Provided by withRouter
  push: (path: string) => void,
  onRemove: (id: number) => void
};

function PostRemoveContainer(props: Props) {
  const id = Number(props.match.params.id);
  const post = find(props.posts, { id });
  const title = get(post, 'title');

  return (
    <ConfirmRemove
      onBack={() => props.push('/posts')}
      onRemove={() => props.onRemove(id)}
    >
      Noticia: <strong>{title}</strong>
    </ConfirmRemove>
  );
}

function mapStateToProps(state) {
  return {
    posts: state.posts
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onRemove: removePost, push }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PostRemoveContainer)
);
