// @flow
import React from 'react';
import Thumbnail from '../Thumbnail/Thumbnail';
import Button from '../Button/Button';
import type { MediaItemType } from '../../types';
import './MediaItems.css';

type Props = {
  items: MediaItemType[],
  editable: boolean,
  onRemove: (id: number) => void
};

export default function MediaItems(props: Props) {
  // Make sure we don't display an empty wrapper.
  if (props.items.length > 0) {
    return (
      <ul className="Media-Items">
        {props.items.map((item, index) => (
          <li className="Media-Items__item" key={item.id}>
            <Thumbnail {...item} />

            {props.editable && (
              <Button
                type="danger"
                size="small"
                className="Media-Items__remove"
                onClick={() => props.onRemove(item.id)}
              >
                Borrar
              </Button>
            )}
          </li>
        ))}
      </ul>
    );
  }
}

MediaItems.defaultProps = {
  items: [],
  editable: false
};
