// @flow

// Smartline API
export const API_ROOT_URL = 'https://api.freyre.com.ar';

// RESTful API Endpoints
export const ENDPOINT_LOGIN = `${API_ROOT_URL}/auth`;
export const ENDPOINT_USER_DETAILS = `${API_ROOT_URL}/me`;
export const ENDPOINT_POSTS = `${API_ROOT_URL}/posts`;
export const ENDPOINT_PAGES = `${API_ROOT_URL}/pages`;
export const ENDPOINT_ROSTER = `${API_ROOT_URL}/roster`;
export const ENDPOINT_CATEGORIES = `${API_ROOT_URL}/categories`;
export const ENDPOINT_MEDIA = `${API_ROOT_URL}/media`;
export const ENDPOINT_OBITUARIES = `${API_ROOT_URL}/obituaries`;
export const ENDPOINT_CLASSIFIEDS = `${API_ROOT_URL}/classifieds`;
export const ENDPOINT_ADS = `${API_ROOT_URL}/ads`;
export const ENDPOINT_AD_TYPES = `${API_ROOT_URL}/ad-types`;
export const ENDPOINT_PHARMACIES = `${API_ROOT_URL}/pharmacies`;
export const ENDPOINT_PHARMACY_ROSTER = `${API_ROOT_URL}/pharmacy-roster`;
export const ENDPOINT_PHARMACY_ROSTER_ENTRIES = `${API_ROOT_URL}/pharmacy-roster-entries/roster`;
export const ENDPOINT_PHARMACY_ROSTER_EXCEPTIONS = `${API_ROOT_URL}/pharmacy-roster-exceptions`;
export const ENDPOINT_MESSAGES = `${API_ROOT_URL}/messages`;

// Error 500
export const INTERNAL_SERVER_ERROR = 'Error interno del servidor';

// Image CDN (Digital Ocean Spaces) Settings
export const DO_REGION = 'nyc3.digitaloceanspaces.com';
export const DO_BUCKET = 'canalcoop';
export const DO_ENDPOINT = `https://${DO_BUCKET}.${DO_REGION}`;
export const DO_ACCESS_KEY = 'LMHJ473A7JQAYH4VRJTT';
export const DO_SECRET_KEY = 'oNCf86o7nakbHJFvMXekfg/fl0jksWnMrQnVRJcodng';

// Posts pagination
export const DEFAULT_PAGINATION_SIZE = 12;
