// @flow
import { SHOW_PROGRESS, HIDE_PROGRESS } from './action-types';
import type { Action } from '../types';

/*
 * This action is in charge of displaying the progress dialog on screen.
 * It takes in `total` which is the number of files being uploaded.
 * This value is used to display the progress percentage as images get uploaded.
 */
export function showProgress(total: number): Action {
  return {
    type: SHOW_PROGRESS,
    reducer: () => total
  };
}

/*
 * This action dismisses the progress dialog, implying all images have been successfully uploaded.
 */
export function hideProgress(): Action {
  return {
    type: HIDE_PROGRESS,
    reducer: () => 0
  };
}
