// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import find from 'lodash/find';
import { updateAd } from '../state/ads';
import AdForm from '../components/AdForm/AdForm';
import type { Match } from 'react-router-dom';
import type { AdType, AdTargetType, ImageType } from '../types';

type Props = {
  ads: AdType[],
  types: AdTargetType[],
  match: Match, // Provided by withRouter
  onSubmit: (
    id: number,
    title: string,
    typeId: number,
    url: string,
    images: ImageType[],
    from: string,
    to: string
  ) => void
};

function AdUpdateContainer(props: Props) {
  const id = Number(props.match.params.id);
  const ad = find(props.ads, { id });
  const onSubmit = (title, typeId, url, images, from, to) =>
    props.onSubmit(id, title, typeId, url, images, from, to);

  return <AdForm {...ad} types={props.types} onSubmit={onSubmit} />;
}

function mapStateToProps(state) {
  return {
    ads: state.ads,
    types: state.adTypes
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onSubmit: updateAd }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdUpdateContainer)
);
