// @flow
import React from 'react';
import spinnerImage from './spinner.png';
import './Spinner.css';

export default function Spinner() {
  return (
    <div className="Spinner">
      <div className="Spinner__image-wrapper">
        <img src={spinnerImage} className="Spinner__image" alt="Cargando..." />
      </div>
    </div>
  );
}
