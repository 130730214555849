// @flow
import React, { Fragment } from 'react';
import Heading from '../components/Heading/Heading';
import Box from '../components/Box/Box';
import RosterRemoveContainer from '../containers/RosterRemove';

export default function RosterRemoveRoute() {
  return (
    <Fragment>
      <Heading>Borrar Turno</Heading>
      <Box>
        <RosterRemoveContainer />
      </Box>
    </Fragment>
  );
}
