// @flow
import React, { Fragment } from 'react';
import Heading from '../components/Heading/Heading';
import Button from '../components/Button/Button';
import Box from '../components/Box/Box';
import PostDetailsContainer from '../containers/PostDetails';

const backButton = <Button to="/posts">Volver</Button>;

export default function PostDetailsRoute() {
  return (
    <Fragment>
      <Heading cta={backButton}>Detalles de la Noticia</Heading>
      <Box fullWidth>
        <PostDetailsContainer />
      </Box>
    </Fragment>
  );
}
