// @flow
import React from 'react';
import classnames from 'classnames';
import './MutedText.css';

type Props = {
  children: string,
  className?: string
};

export default function MutedText(props: Props) {
  const className = classnames('Muted-Text', props.className);

  return <span className={className}>{props.children}</span>;
}
