// @flow
import React, { Fragment } from 'react';
import Filter from '../Filter/Filter';
import Table from '../Table/Table';
import MutedText from '../MutedText/MutedText';
import Badge from '../Badge/Badge';
import Center from '../Center/Center';
import { parseDateTimeFromApi } from '../../utils/dates';
import type { ObituaryType, FilterType } from '../../types';

export type Props = {
  obituaries: ObituaryType[],
  filter: FilterType,
  onToggle: () => void
};

export default function ObituariesList(props: Props) {
  const rows = props.obituaries.map(obituary => ({
    link: `/obituaries/${obituary.id}/edit`,
    cells: [
      obituary.name,
      <MutedText>{obituary.age}</MutedText>,
      <Center>
        <Badge>{obituary.service ? parseDateTimeFromApi(obituary.service) : 'A definir'}</Badge>
      </Center>
    ]
  }));

  return (
    <Fragment>
      <Filter active={props.filter === 'active'} onToggle={props.onToggle}>
        Mostrar únicamente los avisos recientes
      </Filter>

      <Table header={['Nombre', 'Edad', <Center>Oficio Religioso</Center>]} rows={rows} />
    </Fragment>
  );
}
