// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { login } from '../state/token';
import Login from '../components/LoginForm/LoginForm';

type Props = {
  onSubmit: (email: string, password: string) => void
};

function LoginContainer(props: Props) {
  return <Login onSubmit={props.onSubmit} />;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onSubmit: login }, dispatch);
}

export default connect(null, mapDispatchToProps)(LoginContainer);
