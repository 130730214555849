// @flow
import axios from 'axios';
import { showSpinner, hideSpinner } from './spinner';
import { showAlert } from './alerts';
import { RECEIVE_AD_TYPES } from './action-types';
import { ENDPOINT_AD_TYPES, INTERNAL_SERVER_ERROR } from '../utils/constants';
import type { AdTargetType, Action, ThunkAction } from '../types';

function receiveAdTypes(adTypes: AdTargetType[]): Action {
  return {
    type: RECEIVE_AD_TYPES,
    reducer: () => adTypes
  };
}

export function getAdTypes(): ThunkAction {
  return (dispatch, getState) => {
    dispatch(showSpinner());

    axios
      .get(ENDPOINT_AD_TYPES)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          const adTypes = response.data.data;

          dispatch(receiveAdTypes(adTypes));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}
