// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PharmacyRosterDetails, {
  type Props
} from '../components/PharmacyRosterDetails/PharmacyRosterDetails';
import { removeException } from '../state/pharmacyException';

function PharmacyRosterDetailsContainer(props: Props) {
  return <PharmacyRosterDetails {...props} />;
}

function mapStateToProps(state) {
  return state.pharmacyRoster;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onRemoveException: removeException }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyRosterDetailsContainer);
