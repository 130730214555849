// @flow
import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  className?: string,
  disabled: boolean,
  children: string,
  to: string
};

export default function NavigationButton(props: Props) {
  return (
    <Link to={props.to}>
      <button
        type="button"
        className={props.className}
        disabled={props.disabled}
      >
        {props.children}
      </button>
    </Link>
  );
}
