// @flow
import React, { Fragment } from 'react';
import Heading from '../components/Heading/Heading';
import Button from '../components/Button/Button';
import Box from '../components/Box/Box';
import MessageDetailsContainer from '../containers/MessageDetails';

const backButton = <Button to="/messages">Volver</Button>;

export default function MessageDetailsRoute() {
  return (
    <Fragment>
      <Heading cta={backButton}>Detalles del Mensaje</Heading>
      <Box>
        <MessageDetailsContainer />
      </Box>
    </Fragment>
  );
}
