// @flow
import React, { Fragment } from 'react';
import Heading from '../components/Heading/Heading';
import Button from '../components/Button/Button';
import Box from '../components/Box/Box';
import CategoryCreate from '../containers/CategoryCreate';

const cancelButton = <Button to="/categories">Cancelar</Button>;

export default function CategoryCreateRoute() {
  return (
    <Fragment>
      <Heading cta={cancelButton}>Nueva Categoría</Heading>
      <Box>
        <CategoryCreate />
      </Box>
    </Fragment>
  );
}
