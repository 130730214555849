// @flow
import React, { Fragment } from 'react';
import Heading from '../components/Heading/Heading';
import Button from '../components/Button/Button';
import Box from '../components/Box/Box';
import PageDetailsContainer from '../containers/PageDetails';

const backButton = <Button to="/pages">Volver</Button>;

export default function PageDetailsRoute() {
  return (
    <Fragment>
      <Heading cta={backButton}>Detalles de la Página</Heading>
      <Box fullWidth>
        <PageDetailsContainer />
      </Box>
    </Fragment>
  );
}
