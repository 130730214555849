// @flow
import React from 'react';

type Props = {
  className?: string,
  disabled: boolean,
  children: string
};

export default function SubmitButton(props: Props) {
  return (
    <button type="submit" className={props.className} disabled={props.disabled}>
      {props.children}
    </button>
  );
}
