// @flow
import React, { Fragment } from 'react';
import Button from '../components/Button/Button';
import Heading from '../components/Heading/Heading';
import Box from '../components/Box/Box';
import ClassifiedsList from '../containers/ClassifiedsList';

const createClassifiedButton = (
  <Button type="confirm" to="classifieds/create">
    Nuevo Clasificado
  </Button>
);

export default function CategoriesRoute() {
  return (
    <Fragment>
      <Heading cta={createClassifiedButton}>Clasificados</Heading>
      <Box fullWidth>
        <ClassifiedsList />
      </Box>
    </Fragment>
  );
}
