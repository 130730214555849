// @flow
import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Button from '../Button/Button';
import ImageUpload from '../ImageUpload/ImageUpload';
import { parseDateToApi } from '../../utils/dates';
import { createMockImageFileFromUrl } from '../../utils/helpers';
import type { ImageType } from '../../types';
import './ClassifiedForm.css';

type Props = {
  onSubmit: (
    title: string,
    description: string,
    contact: string,
    images: ImageType[],
    from: string,
    to: string
  ) => void,
  id?: number,
  title?: string,
  description?: string,
  contact?: string,
  imageUrl?: string,
  from?: string,
  to?: string
};

type State = {
  title: string,
  description: string,
  contact: string,
  images: ImageType[],
  from: moment,
  to: moment
};

export default class ClassifiedForm extends React.Component<Props, State> {
  state = {
    title: this.props.title || '',
    description: this.props.description || '',
    contact: this.props.contact || '',
    from: this.props.from ? moment(this.props.from) : moment(),
    to: this.props.to ? moment(this.props.to) : moment().add(2, 'weeks'),
    images: this.props.imageUrl
      ? [createMockImageFileFromUrl(this.props.imageUrl)]
      : []
  };

  componentWillReceiveProps(nextProps: Props) {
    const { id, title, description, contact, imageUrl, from, to } = nextProps;
    const shouldUpdateState = id && title;

    if (shouldUpdateState) {
      this.setState(prevState => ({
        title,
        description: description || prevState.description,
        contact: contact || prevState.contact,
        images: imageUrl
          ? [createMockImageFileFromUrl(imageUrl)]
          : prevState.images,
        from: from ? moment(from) : prevState.from,
        to: to ? moment(to) : prevState.to
      }));
    }
  }

  onTitleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      title: event.target.value
    });
  };

  onDescriptionChange = (event: SyntheticInputEvent<HTMLTextAreaElement>) => {
    this.setState({
      description: event.target.value
    });
  };

  onContactChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      contact: event.target.value
    });
  };

  onFromChange = (from: moment) => {
    this.setState({
      from
    });
  };

  onToChange = (to: moment) => {
    this.setState({
      to
    });
  };

  onAddPicture = (images: ImageType[]) => {
    this.setState({
      images
    });
  };

  onRemovePicture = () => {
    this.setState({
      images: []
    });
  };

  onSubmit = () => {
    this.props.onSubmit(
      this.state.title,
      this.state.description,
      this.state.contact,
      this.state.images,
      parseDateToApi(this.state.from),
      parseDateToApi(this.state.to)
    );
  };

  render() {
    return (
      <form method="POST">
        <ul className="List-Form__fields">
          <li className="List-Form__field">
            <label htmlFor="title" className="List-Form__label">
              Título
            </label>
            <input
              id="title"
              type="text"
              className="List-Form__input"
              placeholder="Título del aviso clasificado"
              value={this.state.title}
              onChange={this.onTitleChange}
            />
          </li>

          <li className="List-Form__field">
            <label htmlFor="description" className="List-Form__label">
              Descripción
            </label>
            <textarea
              id="description"
              className="List-Form__textarea"
              placeholder="Detalles del producto o servicio"
              value={this.state.description}
              onChange={this.onDescriptionChange}
            />
          </li>

          <li className="List-Form__field">
            <label htmlFor="contact" className="List-Form__label">
              Contacto
            </label>
            <input
              id="contact"
              type="text"
              className="List-Form__input"
              placeholder="Teléfono o dirección de contacto"
              value={this.state.contact}
              onChange={this.onContactChange}
            />
          </li>

          <li className="List-Form__field">
            <label className="List-Form__label">
              Fecha de inicio del aviso clasificado
            </label>
            <DatePicker
              selected={this.state.from}
              onChange={this.onFromChange}
              dateFormat="dddd D [de] MMMM [de] YYYY"
              locale="es-ar"
            />
          </li>

          <li className="List-Form__field">
            <label className="List-Form__label">
              Fecha de finalización del aviso clasificado
            </label>
            <DatePicker
              selected={this.state.to}
              onChange={this.onToChange}
              dateFormat="dddd D [de] MMMM [de] YYYY"
              locale="es-ar"
            />
          </li>

          <li className="List-Form__field">
            <ImageUpload
              label="Foto (opcional)"
              className="Classified-Form__image-wrapper"
              files={this.state.images}
              onAddFile={this.onAddPicture}
              onRemoveFile={this.onRemovePicture}
            />
          </li>

          <li className="List-Form__field">
            <Button
              type="confirm"
              className="Classified-Form__button"
              onClick={this.onSubmit}
            >
              Guardar
            </Button>

            {this.props.id && (
              <Button
                type="danger"
                className="Classified-Form__button"
                to={`/classifieds/${this.props.id}/remove`}
              >
                Borrar
              </Button>
            )}
          </li>
        </ul>
      </form>
    );
  }
}
