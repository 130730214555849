// @flow
import moment from 'moment';
import 'moment/locale/es';

export function setupLocale() {
  moment.locale('es');
}

export function parseDateFromApi(date: string): string {
  return moment(date).format('dddd D [de] MMMM [de] YYYY');
}

export function parseDateTimeFromApi(date: string): string {
  return moment(date).format('dddd D [de] MMMM [de] YYYY [a las] HH:mm [hs.]');
}

// Make sure the resulting datetime string has a timezone, e.g. 2018-11-01T14:30:13-03:00.
// The RESTful API converts all dates to UTC.
// Missing the timezone field in the date we pass in from the dashboard
// will result in the incorrect datetime being stored to the database.
export function parseDateToApi(date: moment): string {
  return date.format();
}
