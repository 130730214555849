// @flow
import type { ImageType } from '../types';

/*
 * We are using react-rte as our rich text (wysiwyg) editor.
 * The editor is initialised with the following HTML code:
 * <p><br></p>
 * So even if the user didn't input anything on some of the fields,
 * we will be sending that to the API.
 * This helper function is our way to check whether the content
 * submitted by the user is empty or not.
 * We don't care about spaces and breaklines.
 */
export function markupIsEmpty(content: string): boolean {
  const element = document.createElement('div');

  element.innerHTML = content;

  return element.textContent.length === 0 || !element.textContent.trim();
}

export function isArray(arg: any): boolean {
  return Object.prototype.toString.call(arg) === '[object Array]';
}

export function getVideoIdFromUrl(url: string): ?string {
  if (url.match('https?://(www.)?youtube|youtu.be')) {
    return url.split(/v\/|v=|youtu\.be\//)[1].split(/[?&]/)[0];
  } else {
    return null;
  }
}

export function getImageThumbnailUrl(url: string): string {
  // @TODO: make sure `url` matches a Cloudinary resource URL
  return url.replace('image/upload/', 'image/upload/t_media_lib_thumb/');
}

export function createMockImageFileFromUrl(url: string): ImageType {
  const file = new File([''], 'filename');

  file.preview = url;

  return file;
}

export function isMockImageFile(image: ImageType): boolean {
  return image.size === 0 && image.type === '';
}

export function getRandomFileName(fileName: string) {
  const date = new Date();
  const timestamp = date.getTime();
  const randomString = Math.random()
    .toString(36)
    .replace('0.', '');
  const fileExtension = fileName.split('.').pop();

  return `${timestamp}-${randomString}.${fileExtension}`;
}
