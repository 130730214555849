// @flow
import React, { Fragment } from 'react';
import get from 'lodash/get';
import Filter from '../Filter/Filter';
import MutedText from '../MutedText/MutedText';
import Table from '../Table/Table';
import type { AdType, FilterType } from '../../types';

export type Props = {
  ads: AdType[],
  filter: FilterType,
  onToggle: () => void
};

export default function AdList(props: Props) {
  const header = ['Título', 'Alcance'];
  const rows = props.ads.map(ad => {
    const description = (
      <MutedText>{get(ad, 'type.description', '-')}</MutedText>
    );

    return {
      link: `/ads/${ad.id}/edit`,
      cells: [ad.title, description]
    };
  });

  return (
    <Fragment>
      <Filter active={props.filter === 'active'} onToggle={props.onToggle}>
        Mostrar únicamente las publicidades activas
      </Filter>

      <Table header={header} rows={rows} />
    </Fragment>
  );
}
