// @flow
import React, { Fragment } from 'react';
import Heading from '../components/Heading/Heading';
import Box from '../components/Box/Box';
import MessagesListContainer from '../containers/MessagesList';

export default function ObituariesListRoute() {
  return (
    <Fragment>
      <Heading>Mensajes Enviados desde el Sitio Web</Heading>
      <Box>
        <MessagesListContainer />
      </Box>
    </Fragment>
  );
}
