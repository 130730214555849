// @flow
import { combineReducers } from 'redux';
import { routerReducer as router } from 'react-router-redux';
import { reduce } from '../utils/redux';
import {
  SHOW_ALERT,
  HIDE_ALERT,
  RECEIVE_CATEGORIES,
  RECEIVE_AD_TYPES,
  RECEIVE_ADS,
  FILTER_ADS,
  RECEIVE_CLASSIFIEDS,
  FILTER_CLASSIFIEDS,
  RECEIVE_OBITUARIES,
  FILTER_OBITUARIES,
  RECEIVE_MESSAGES,
  RECEIVE_PAGES,
  RECEIVE_POSTS,
  RECEIVE_UPDATED_POST,
  REMOVE_POST,
  REMOVE_MEDIA,
  INCREMENT_PAGINATION_INDEX,
  RESET_PAGINATION_INDEX,
  RECEIVE_PHARMACIES,
  RECEIVE_PHARMACY_ROSTER,
  SHOW_PROGRESS,
  HIDE_PROGRESS,
  RECEIVE_ROSTER,
  SHOW_SPINNER,
  HIDE_SPINNER,
  RECEIVE_LOGIN,
  RECEIVE_LOGOUT,
  RECEIVE_USER_DETAILS
} from './action-types';
import type { State, Action } from '../types';

const appReducer = combineReducers({
  router,
  ads: reduce(RECEIVE_ADS, []),
  adTypes: reduce(RECEIVE_AD_TYPES, []),
  adsFilter: reduce(FILTER_ADS, 'active'),
  alerts: reduce([SHOW_ALERT, HIDE_ALERT], []),
  categories: reduce(RECEIVE_CATEGORIES, []),
  classifieds: reduce(RECEIVE_CLASSIFIEDS, []),
  classifiedsFilter: reduce(FILTER_CLASSIFIEDS, 'active'),
  messages: reduce(RECEIVE_MESSAGES, []),
  obituaries: reduce(RECEIVE_OBITUARIES, []),
  obituariesFilter: reduce(FILTER_OBITUARIES, 'active'),
  pages: reduce(RECEIVE_PAGES, []),
  pharmacies: reduce(RECEIVE_PHARMACIES, []),
  pharmacyRoster: reduce(RECEIVE_PHARMACY_ROSTER, {}),
  posts: reduce([RECEIVE_POSTS, RECEIVE_UPDATED_POST, REMOVE_POST, REMOVE_MEDIA], []),
  postsPaginationIndex: reduce([INCREMENT_PAGINATION_INDEX, RESET_PAGINATION_INDEX], 1),
  progress: reduce([SHOW_PROGRESS, HIDE_PROGRESS], 0),
  roster: reduce(RECEIVE_ROSTER, []),
  spinner: reduce([SHOW_SPINNER, HIDE_SPINNER], false),
  token: reduce([RECEIVE_LOGIN, RECEIVE_LOGOUT], null),
  user: reduce(RECEIVE_USER_DETAILS, null)
});

/*
 * CLEAN UP REDUX STORE ON USER LOGOUT:
 * The root reducer would normally delegate handling the action
 * to the reducer generated by combineReducers().
 * However, whenever it receives USER_LOGOUT action,
 * it returns the initial state all over again.
 * Reducers are supposed to return the initial state when they are called
 * with `undefined` as the first argument, no matter the action.
 * Let's use this fact to conditionally strip the accumulated state
 * as we pass it to appReducer.
 * Now, whenever `RECEIVE_LOGOUT` fires, all reducers will be initialized anew.
 */
const rootReducer = (state: State, action: Action) => {
  if (action.type === RECEIVE_LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
