// @flow
import React, { Fragment } from 'react';
import Heading from '../components/Heading/Heading';
import Button from '../components/Button/Button';
import Box from '../components/Box/Box';
import PageCreateContainer from '../containers/PageCreate';

const cancelButton = <Button to="/pages">Cancelar</Button>;

export default function PageCreateRoute() {
  return (
    <Fragment>
      <Heading cta={cancelButton}>Nueva Página</Heading>
      <Box>
        <PageCreateContainer />
      </Box>
    </Fragment>
  );
}
