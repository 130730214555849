// @flow
import React from 'react';
import moment from 'moment';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import Button from '../Button/Button';
import { parseDateToApi } from '../../utils/dates';
import type { PharmacyType } from '../../types';

type Props = {
  onSubmit: (from: string, to: string, pharmacyId: number) => void,
  pharmacies: PharmacyType[]
};

type State = {
  from: moment,
  to: moment,
  pharmacyId: number
};

export default class PharmacyExceptionForm extends React.Component<Props, State> {
  state = {
    from: moment(),
    to: moment().add(2, 'days'),
    pharmacyId: 0
  };

  onFromChange = (from: moment) => {
    this.setState({
      from
    });
  };

  onToChange = (to: moment) => {
    this.setState({
      to
    });
  };

  onPharmacyChange = (pharmacy: PharmacyType) => {
    this.setState({
      pharmacyId: pharmacy.id
    });
  };

  onSubmit = () => {
    this.props.onSubmit(
      parseDateToApi(this.state.from),
      parseDateToApi(this.state.to),
      this.state.pharmacyId
    );
  };

  render() {
    return (
      <form method="POST">
        <ul className="List-Form__fields">
          <li className="List-Form__field">
            <label className="List-Form__label">Fecha de inicio de la excepción</label>
            <DatePicker
              selected={this.state.from}
              onChange={this.onFromChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="LLL"
              timeCaption="Hora"
              locale="es-ar"
            />
          </li>

          <li className="List-Form__field">
            <label className="List-Form__label">Fecha de finalización de la excepción</label>
            <DatePicker
              selected={this.state.to}
              onChange={this.onToChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="LLL"
              timeCaption="Hora"
              locale="es-ar"
            />
          </li>

          <li className="List-Form__field">
            <label className="List-Form__label" htmlFor="pharmacy">
              Farmacia
            </label>
            <Select
              id="pharmacy"
              valueKey="id"
              labelKey="name"
              className="List-Form__input"
              placeholder="Seleccione una farmacia"
              value={this.state.pharmacyId}
              options={this.props.pharmacies}
              onChange={this.onPharmacyChange}
            />
          </li>

          <li className="List-Form__field">
            <Button type="confirm" onClick={this.onSubmit}>
              Guardar
            </Button>
          </li>
        </ul>
      </form>
    );
  }
}
