// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toggleAdsFilter } from '../state/ads';
import AdsList from '../components/AdList/AdList';
import type { Props } from '../components/AdList/AdList';

function AdListContainer(props: Props) {
  return (
    <AdsList ads={props.ads} filter={props.filter} onToggle={props.onToggle} />
  );
}

function mapStateToProps(state) {
  return {
    filter: state.adsFilter,
    ads: state.ads
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onToggle: toggleAdsFilter }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AdListContainer);
