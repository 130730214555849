// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import find from 'lodash/find';
import { updateCategory } from '../state/categories';
import CategoryForm from '../components/CategoryForm/CategoryForm';
import type { Match } from 'react-router-dom';
import type { CategoryType } from '../types';

type Props = {
  categories: CategoryType[],
  match: Match, // Provided by withRouter
  onRemove: (id: number) => void,
  onSubmit: (
    id: number,
    name: string,
    description: string,
    color: string
  ) => void
};

function CategoryUpdateContainer(props: Props) {
  const id = Number(props.match.params.id);
  const category: ?CategoryType = find(props.categories, { id });
  const onSubmit = (name, description, color) =>
    props.onSubmit(id, name, description, color);

  return <CategoryForm {...category} onSubmit={onSubmit} />;
}

function mapStateToProps(state) {
  return {
    categories: state.categories
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onSubmit: updateCategory }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CategoryUpdateContainer)
);
