// @flow
import React, { Fragment } from 'react';
import Heading from '../components/Heading/Heading';
import Button from '../components/Button/Button';
import Box from '../components/Box/Box';
import ClassifiedCreate from '../containers/ClassifiedCreate';

const cancelButton = <Button to="/classifieds">Cancelar</Button>;

export default function ClassifiedCreateRoute() {
  return (
    <Fragment>
      <Heading cta={cancelButton}>Nuevo Clasificado</Heading>
      <Box>
        <ClassifiedCreate />
      </Box>
    </Fragment>
  );
}
