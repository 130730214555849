// @flow
import React from 'react';
import Button from '../Button/Button';
import './LoginForm.css';
import type { CredentialsType } from '../../types';

const ENTER_KEY_CODE = 13;

type Props = {
  onSubmit: (credentials: CredentialsType) => void
};

type State = CredentialsType;

export default class Login extends React.Component<Props, State> {
  state = {
    email: '',
    password: ''
  };

  // @TODO: not sure how to type this event
  onKeyDown = (event: SyntheticKeyboardEvent<>) => {
    if (event.keyCode === ENTER_KEY_CODE) {
      this.onSubmit();
    }
  };

  onUsernameChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      email: event.target.value
    });
  };

  onPasswordChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      password: event.target.value
    });
  };

  onSubmit = () => {
    this.props.onSubmit({
      email: this.state.email,
      password: this.state.password
    });
  };

  render() {
    return (
      <form method="POST" className="Login-Form">
        <ul className="List-Form__fields">
          <li className="List-Form__field Login-Form__field">
            <input
              autoFocus
              type="text"
              className="Login-Form__input"
              placeholder="Usuario"
              value={this.state.email}
              onKeyDown={this.onKeyDown}
              onChange={this.onUsernameChange}
            />
          </li>

          <li className="List-Form__field Login-Form__field">
            <input
              type="password"
              className="Login-Form__input"
              placeholder="Contraseña"
              value={this.state.password}
              onKeyDown={this.onKeyDown}
              onChange={this.onPasswordChange}
            />
          </li>

          <li className="List-Form__field">
            <Button
              type="confirm"
              size="large"
              className="Login-Form__button"
              onClick={this.onSubmit}
            >
              Iniciar Sesión
            </Button>
          </li>
        </ul>
      </form>
    );
  }
}
