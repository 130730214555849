// @flow
import axios from 'axios';
import { push } from 'react-router-redux';
import { showSpinner, hideSpinner } from './spinner';
import { showAlert } from './alerts';
import { bootstrap } from './bootstrap';
import { setToken, clearToken } from '../utils/token';
import { RECEIVE_LOGIN, RECEIVE_LOGOUT } from './action-types';
import { ENDPOINT_LOGIN, INTERNAL_SERVER_ERROR } from '../utils/constants';
import type { CredentialsType, Action, ThunkAction } from '../types';

export function receiveLogin(token: string): Action {
  return {
    type: RECEIVE_LOGIN,
    reducer: () => token
  };
}

function receiveLogout(): Action {
  return {
    type: RECEIVE_LOGOUT,
    reducer: () => null
  };
}

export function login(credentials: CredentialsType): ThunkAction {
  return (dispatch, getState) => {
    dispatch(showSpinner());

    axios
      .post(ENDPOINT_LOGIN, credentials, {
        // Reject only if the status code is greater than or equal to 500
        validateStatus: (status: number) => status < 500
      })
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          const token = response.data.data.token;

          // Save token to Redux store
          dispatch(receiveLogin(token));

          // Save token to localStorage
          setToken(token);

          // Bootstrap application state
          dispatch(bootstrap());

          // Push
          dispatch(push('/'));
        } else if (response.status === 401) {
          dispatch(showAlert('Usuario o contraseña incorrectos', 'error'));
          dispatch(hideSpinner());
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}

export function logout(): ThunkAction {
  return dispatch => {
    dispatch(showSpinner());
    dispatch(receiveLogout());
    clearToken();
    dispatch(hideSpinner());
    dispatch(push('/login'));
  };
}
