// @flow
import React, { Fragment } from 'react';
import Table from '../Table/Table';
import Button from '../Button/Button';
import Badge from '../Badge/Badge';
import MutedText from '../MutedText/MutedText';
import type { PharmacyRosterType } from '../../types';
import './PharmacyRosterDetails.css';

export type Props = PharmacyRosterType & {
  onRemoveException: (id: number) => void
};

export default function PharmacyRosterDetails(props: Props) {
  const entries = props.entries || [];
  const exceptions = props.exceptions || [];
  const entriesRows = entries.map(entry => ({
    cells: [
      <Fragment>
        <MutedText>{`${entry.order.toString()}) `}</MutedText> {entry.pharmacyName}
      </Fragment>,
      entry.pharmacyAddress,
      entry.pharmacyPhone
    ]
  }));
  const exceptionsRows = exceptions.map(exception => ({
    cells: [
      exception.pharmacyName,
      <Badge>{exception.from}</Badge>,
      <Badge>{exception.to}</Badge>,
      <Button type="danger" size="small" onClick={() => props.onRemoveException(exception.id)}>
        Borrar
      </Button>
    ]
  }));

  return (
    <Fragment>
      <Table header={['Farmacia', 'Dirección', 'Teléfono']} rows={entriesRows} />
      <div className="Pharmacy-Roster-Details__exceptions">
        <Table header={['Excepción', 'Desde', 'Hasta', '']} rows={exceptionsRows} />
      </div>
    </Fragment>
  );
}
