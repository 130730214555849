// @flow
import type { Action } from '../types';
import { isArray } from './helpers';

export function reduce(actionType: string | string[], initialState: any) {
  return function(state: any = initialState, action: Action) {
    if (!action.hasOwnProperty('type') || !action.hasOwnProperty('reducer')) {
      return state;
    }

    const matches =
      (isArray(actionType) && actionType.indexOf(action.type) > -1) ||
      (typeof actionType === 'string' && actionType === action.type);

    if (matches) {
      return action.reducer(state);
    }

    return state;
  };
}
