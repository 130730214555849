// @flow
import * as React from 'react';
import classnames from 'classnames';
import './Box.css';

type Props = {
  children?: React.Node,
  fullWidth: boolean
};

export default function Box(props: Props) {
  const className = classnames('Box', {
    'Box--full-width': props.fullWidth
  });

  return (
    <div className={className}>
      <div className="Box__content">{props.children}</div>
    </div>
  );
}

Box.defaultProps = {
  fullWidth: false
};
