// @flow
import React from 'react';
import { connect } from 'react-redux';
import CategoryList from '../components/CategoryList/CategoryList';
import type { CategoryType } from '../types';

type Props = {
  categories: CategoryType[]
};

function CategoriesListContainer(props: Props) {
  return <CategoryList categories={props.categories} />;
}

function mapStateToProps(state) {
  return {
    categories: state.categories
  };
}

export default connect(mapStateToProps)(CategoriesListContainer);
