// @flow
import React, { Fragment } from 'react';
import Heading from '../components/Heading/Heading';
import Button from '../components/Button/Button';
import Box from '../components/Box/Box';
import CategoryUpdate from '../containers/CategoryUpdate';

const cancelButton = <Button to="/categories">Cancelar</Button>;

export default function CategoryUpdateRoute() {
  return (
    <Fragment>
      <Heading cta={cancelButton}>Actualizar Categoría</Heading>
      <Box>
        <CategoryUpdate />
      </Box>
    </Fragment>
  );
}
