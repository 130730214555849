// @flow
import React, { Fragment } from 'react';
import Heading from '../components/Heading/Heading';
import Button from '../components/Button/Button';
import Box from '../components/Box/Box';
import RosterListContainer from '../containers/RosterList';

const createEntryButton = (
  <Button type="confirm" to="roster/create">
    Nuevo Turno
  </Button>
);

export default function RosterListRoute() {
  return (
    <Fragment>
      <Heading cta={createEntryButton}>Turnos</Heading>
      <Box>
        <RosterListContainer />
      </Box>
    </Fragment>
  );
}
