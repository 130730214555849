// @flow
import React, { Fragment } from 'react';
import Button from '../components/Button/Button';
import Heading from '../components/Heading/Heading';
import Box from '../components/Box/Box';
import PostsListContainer from '../containers/PostsList';

const createPostButton = (
  <Button type="confirm" to="posts/create">
    Nueva Noticia
  </Button>
);

export default function PostsListRoute() {
  return (
    <Fragment>
      <Heading cta={createPostButton}>Archivo de Noticias</Heading>
      <Box fullWidth>
        <PostsListContainer />
      </Box>
    </Fragment>
  );
}
