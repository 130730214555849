// @flow
import React from 'react';
import RosterForm from '../RosterForm/RosterForm';
import type { RosterType } from '../../types';
import './RosterList.css';

type Props = {
  entries: RosterType[],
  onUpdate: (id: number, activity: string, name: string, phone: string) => void
};

export default function RosterList(props: Props) {
  return props.entries.map(entry => {
    const onUpdate = (activity: string, name: string, phone: string) =>
      props.onUpdate(entry.id, activity, name, phone);

    return (
      <div className="Roster-List__item" key={entry.id}>
        {entry.date && (
          <header className="Roster-List__item-header">
            Actualizado por última vez el <strong>{entry.date}</strong>
            {entry.outdated && (
              <span className="Roster-List__item-outdated">Desactualizado</span>
            )}
          </header>
        )}

        <div className="Roster-List__item-form">
          <RosterForm
            id={entry.id}
            activity={entry.activity}
            name={entry.name}
            phone={entry.phone}
            onSubmit={onUpdate}
          />
        </div>
      </div>
    );
  });
}
