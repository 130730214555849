// @flow
import React from 'react';
import './Progress.css';

type Props = {
  total: number
};

export default function Progress(props: Props) {
  const title = `Subiendo ${props.total} ${
    props.total === 1 ? 'foto' : 'fotos'
  }`;

  return (
    <div className="Progress">
      <div className="Progress__content">
        <p className="Progress__title">{title}</p>

        <div className="Progress__spinner">
          <div className="Progress__spinner-double-bounce1" />
          <div className="Progress__spinner-double-bounce2" />
        </div>

        <p className="Progress__blurb">
          Por favor, <strong>no cierre esta pestaña del navegador</strong> hasta
          que este mensaje desaparezca. De lo contrario, sus archivos no se
          adjuntarán correctamente.
        </p>
      </div>
    </div>
  );
}
