// @flow
import React, { Fragment } from 'react';
import Heading from '../components/Heading/Heading';
import Box from '../components/Box/Box';
import ObituaryRemove from '../containers/ObituaryRemove';

export default function ObituaryRemoveRoute() {
  return (
    <Fragment>
      <Heading>Borrar Aviso Necrológico</Heading>
      <Box>
        <ObituaryRemove />
      </Box>
    </Fragment>
  );
}
