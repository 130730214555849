// @flow
import React from 'react';
import ColorPreview from '../ColorPreview/ColorPreview';
import Center from '../Center/Center';
import MutedText from '../MutedText/MutedText';
import Badge from '../Badge/Badge';
import Table from '../Table/Table';
import type { CategoryType } from '../../types';

type Props = {
  categories: CategoryType[]
};

export default function CategoryList(props: Props) {
  const header = ['Categoría', 'Descripción', <Center>Color</Center>, <Center>Noticias</Center>];

  const rows = props.categories.map(category => {
    const description = <MutedText>{category.description || '-'}</MutedText>;
    const colorPreview = (
      <Center>
        <ColorPreview color={category.color} />
      </Center>
    );
    const postCount = (
      <Center>
        <Badge>{category.posts}</Badge>
      </Center>
    );

    return {
      link: `/categories/${category.id}/edit`,
      cells: [category.name, description, colorPreview, postCount]
    };
  });

  return <Table header={header} rows={rows} />;
}
