// @flow
import React from 'react';
import get from 'lodash/get';
import Editor from '../Editor/Editor';
import Button from '../Button/Button';
import MediaItems from '../MediaItems/MediaItems';
import { markupIsEmpty } from '../../utils/helpers';
import { parseDateFromApi } from '../../utils/dates';
import type { MediaItemType } from '../../types';
import './PostPreview.css';

type Props = {
  id: number,
  title: string,
  body: string,
  date: string,
  category: {
    id: number,
    name: string
  },
  excerpt: string,
  media: MediaItemType[]
};

export default function PostPreview(props: Props) {
  const categoryName = get(props.category, 'name', 'Sin categoría');
  const categoryColor = get(props.category, 'color');

  return (
    <article className="Post-Preview">
      <header>
        <h1 className="Post-Preview__title">{props.title}</h1>
        <ul className="Post-Preview__list">
          <li className="Post-Preview__list-item">
            <span
              className="Post-Preview__badge"
              style={{
                backgroundColor: categoryColor
              }}
            >
              {categoryName}
            </span>
            <span className="Post-Preview__badge">#{props.id}</span>
            <span className="Post-Preview__badge">Publicado: {props.date}</span>

            {props.expirationDate && (
              <span className="Post-Preview__badge">
                Expira: {parseDateFromApi(props.expirationDate)}
              </span>
            )}
          </li>
          <li className="Post-Preview__list-item">
            <Button
              type="primary"
              size="small"
              className="Post-Preview__button"
              to={`/posts/${props.id}/edit`}
            >
              Modificar
            </Button>

            <Button
              type="danger"
              size="small"
              className="Post-Preview__button"
              to={`/posts/${props.id}/remove`}
            >
              Borrar
            </Button>
          </li>
        </ul>
      </header>

      <p className="Post-Preview__excerpt">{props.excerpt}</p>

      {!markupIsEmpty(props.body) && <Editor readOnly>{props.body}</Editor>}

      {props.media.length > 0 && (
        <div className="Post-Preview__media">
          <MediaItems items={props.media} />
        </div>
      )}
    </article>
  );
}

PostPreview.defaultProps = {
  media: []
};
