// User Details
export const RECEIVE_USER_DETAILS = 'USER_DETAILS/RECEIVE';

// Sticky Alert Messages
export const SHOW_ALERT = 'ALERT/SHOW';
export const HIDE_ALERT = 'ALERT/HIDE';

// Loading Spinner
export const SHOW_SPINNER = 'SPINNER/SHOW';
export const HIDE_SPINNER = 'SPINNER/HIDE';

// File upload progress dialog
export const SHOW_PROGRESS = 'PROGESS/SHOW';
export const UPDATE_PROGRESS = 'PROGRESS/UPDATE';
export const HIDE_PROGRESS = 'PROGRESS/HIDE';

// Authentication
export const RECEIVE_LOGIN = 'AUTH/LOGIN';
export const RECEIVE_LOGOUT = 'AUTH/LOGOUT';

// Roster
export const RECEIVE_ROSTER = 'ROSTER/RECEIVE';

// Pages
export const RECEIVE_PAGES = 'PAGES/RECEIVE';

// Categories
export const RECEIVE_CATEGORIES = 'CATEGORIES/RECEIVE';

// Posts
export const RECEIVE_POSTS = 'POSTS/RECEIVE';
export const RECEIVE_UPDATED_POST = 'UPDATED_POST/RECEIVE';
export const INCREMENT_PAGINATION_INDEX = 'POSTS/INCREMENT_PAGINATION_INDEX';
export const RESET_PAGINATION_INDEX = 'POSTS/RESET_PAGINATION_INDEX';
export const REMOVE_POST = 'POSTS/REMOVE';
export const REMOVE_MEDIA = 'MEDIA/REMOVE';

// Obituaries
export const RECEIVE_OBITUARIES = 'OBITUARIES/RECEIVE';
export const FILTER_OBITUARIES = 'OBITUARIES/FILTER';

// Classifieds
export const RECEIVE_CLASSIFIEDS = 'CLASSIFIEDS/RECEIVE';
export const FILTER_CLASSIFIEDS = 'CLASSIFIEDS/FILTER';

// Ads
export const RECEIVE_AD_TYPES = 'AD_TYPES/RECEIVE';
export const RECEIVE_ADS = 'ADS/RECEIVE';
export const FILTER_ADS = 'ADS/FILTER';

// Pharmacy Roster
export const RECEIVE_PHARMACIES = 'PHARMACY_ROSTER/RECEIVE_PHARMACIES';
export const RECEIVE_PHARMACY_ROSTER = 'PHARMACY_ROSTER/RECEIVE_ROSTER_DETAILS';

// Messages
export const RECEIVE_MESSAGES = 'MESSAGES/RECEIVE';
