// @flow
import React, { Fragment } from 'react';
import Button from '../components/Button/Button';
import Heading from '../components/Heading/Heading';
import Box from '../components/Box/Box';
import AdListContainer from '../containers/AdList';

const createAdButton = (
  <Button type="confirm" to="ads/create">
    Nueva Publicidad
  </Button>
);

export default function ObituariesListRoute() {
  return (
    <Fragment>
      <Heading cta={createAdButton}>Publicidades</Heading>
      <Box fullWidth>
        <AdListContainer />
      </Box>
    </Fragment>
  );
}
