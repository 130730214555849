// @flow
import React from 'react';
import classnames from 'classnames';
import './Badge.css';

type Props = {
  children: string | number,
  className?: string
};

export default function Badge(props: Props) {
  const className = classnames('Badge', props.className);

  return <span className={className}>{props.children}</span>;
}
