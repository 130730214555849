// @flow
import axios from 'axios';
import { push } from 'react-router-redux';
import { showSpinner, hideSpinner } from './spinner';
import { showAlert } from './alerts';
import { getPharmacyRoster } from './pharmacyRoster';
import { ENDPOINT_PHARMACY_ROSTER_EXCEPTIONS, INTERNAL_SERVER_ERROR } from '../utils/constants';
import type { ThunkAction } from '../types';

export function createException(from: string, to: string, pharmacyId: number): ThunkAction {
  return (dispatch, getState) => {
    dispatch(showSpinner());

    const rosterId = getState().pharmacyRoster.id;
    const payload = {
      roster_id: rosterId,
      pharmacy_id: pharmacyId,
      from,
      to
    };

    axios
      .post(ENDPOINT_PHARMACY_ROSTER_EXCEPTIONS, payload)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          dispatch(showAlert('Excepción creada con éxito!', 'success'));
          dispatch(getPharmacyRoster());
          dispatch(push('/pharmacies'));
        } else {
          dispatch(showAlert('Error al intentar crear la excepción', 'error'));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}

export function removeException(id: number): ThunkAction {
  return (dispatch, getState) => {
    dispatch(showSpinner());

    axios
      .delete(`${ENDPOINT_PHARMACY_ROSTER_EXCEPTIONS}/${id}`)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          dispatch(showAlert('La excepción se borró con éxito!', 'success'));
          dispatch(getPharmacyRoster());
          dispatch(push('/pharmacies'));
        } else {
          dispatch(showAlert('Error al borrar la excepción', 'error'));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}
