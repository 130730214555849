// @flow
import axios from 'axios';
import find from 'lodash/find';
import { push } from 'react-router-redux';
import { showSpinner, hideSpinner } from './spinner';
import { showAlert } from './alerts';
import { uploadImages } from './imageUpload';
import {
  RECEIVE_POSTS,
  RECEIVE_UPDATED_POST,
  INCREMENT_PAGINATION_INDEX,
  RESET_PAGINATION_INDEX,
  REMOVE_POST,
  REMOVE_MEDIA
} from './action-types';
import {
  ENDPOINT_POSTS,
  ENDPOINT_MEDIA,
  DEFAULT_PAGINATION_SIZE,
  INTERNAL_SERVER_ERROR
} from '../utils/constants';
import { parseDateFromApi } from '../utils/dates';
import type { PostType, PostFormType, Action, ThunkAction } from '../types';

function receivePosts(posts: PostType[]): Action {
  return {
    type: RECEIVE_POSTS,
    reducer: state => state.concat(posts)
  };
}

function _removePost(id: number): Action {
  return {
    type: REMOVE_POST,
    reducer: state => state.filter(post => post.id !== id)
  };
}

function receiveUpdatedPost(updatedPost: PostType): Action {
  return {
    type: RECEIVE_UPDATED_POST,
    reducer: state => {
      const postAlreadyExists = find(state, { id: updatedPost.id });

      if (postAlreadyExists) {
        return state.map(post => {
          if (post.id === updatedPost.id) {
            return updatedPost;
          }

          return post;
        });
      } else {
        return state.concat(updatedPost);
      }
    }
  };
}

function incrementPaginationIndex(): Action {
  return {
    type: INCREMENT_PAGINATION_INDEX,
    reducer: state => state + 1
  };
}

function resetPaginationIndex(): Action {
  return {
    type: RESET_PAGINATION_INDEX,
    reducer: () => 1
  };
}

export function getPosts(): ThunkAction {
  return (dispatch, getState) => {
    dispatch(showSpinner());

    const paginationIndex = getState().postsPaginationIndex;
    const paginationSize = DEFAULT_PAGINATION_SIZE;
    const endpoint = `${ENDPOINT_POSTS}/page/${paginationIndex}/size/${paginationSize}`;

    axios
      .get(endpoint)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          const posts = response.data.data.map(post => ({
            id: post.id,
            title: post.title,
            excerpt: post.excerpt,
            body: post.body,
            category: post.category,
            media: post.media,
            date: parseDateFromApi(post.created_at),
            expirationDate: post.expired_at
          }));

          dispatch(receivePosts(posts));
          dispatch(incrementPaginationIndex());
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}

export function getPostById(id: number): ThunkAction {
  return (dispatch, getState) => {
    dispatch(showSpinner());

    const endpoint = `${ENDPOINT_POSTS}/${id}`;

    axios
      .get(endpoint)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          const data = response.data.data;
          const post = {
            id: data.id,
            title: data.title,
            excerpt: data.excerpt,
            body: data.body,
            category: data.category,
            media: data.media,
            date: parseDateFromApi(data.created_at),
            expirationDate: data.expired_at
          };

          dispatch(receiveUpdatedPost(post));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}

export function removePost(id: number): ThunkAction {
  return (dispatch, getState) => {
    dispatch(showSpinner());

    const endpoint = `${ENDPOINT_POSTS}/${id}`;

    axios
      .delete(endpoint)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          dispatch(showAlert('La noticia se borró con éxito!', 'success'));
          dispatch(resetPaginationIndex());
          dispatch(_removePost(id));
          dispatch(push('/posts'));
        } else {
          dispatch(showAlert('Error al borrar la noticia', 'error'));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}

/*
 * This helper function contains the shared logic for both
 * creating and updating a post, which are esentially the same.
 */
export function savePost(data: PostFormType): ThunkAction {
  return dispatch => {
    const imagesToUpload = data.files;
    const uploadImagesPromise = dispatch(uploadImages(imagesToUpload));

    uploadImagesPromise.then(urls => {
      // All images (if any) have been successfully uploaded at this point.
      dispatch(showSpinner());

      // Post only the data we need to the server.
      const post = {
        title: data.title,
        category_id: data.categoryId,
        excerpt: data.excerpt,
        body: data.body,
        expired_at: data.expirationDate
      };

      // This action contains the shared logic for both creating
      // and updating a Post. If we receive a valid post ID then
      // we will be updating it. Otherwise we create a new one.
      const ajaxCall = () =>
        !!data.id
          ? axios.put(`${ENDPOINT_POSTS}/${data.id}`, post)
          : axios.post(ENDPOINT_POSTS, post);

      ajaxCall()
        .then(response => {
          // We need to have access to the post ID to link
          // our new media items to this new post.
          const id = data.id || response.data.data.id;

          const videosPromises = data.videos.map(video => {
            const payload = {
              post_id: id,
              type: 'video',
              url: video.url
            };

            return axios.post(ENDPOINT_MEDIA, payload);
          });

          const imagesPromises = urls.map(url => {
            const payload = {
              post_id: id,
              type: 'image',
              url: url
            };

            return axios.post(ENDPOINT_MEDIA, payload);
          });

          Promise.all([...videosPromises, ...imagesPromises]).then(() => {
            dispatch(hideSpinner());
            dispatch(showAlert('Noticia publicada con éxito!', 'success'));
            dispatch(getPostById(id));
            dispatch(push(`/posts/${id}`));
          });
        })
        .catch((error: TypeError) => {
          dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
          dispatch(hideSpinner());
        });
    });
  };
}

function _removeMediaItem(postId: number, itemId: number) {
  return {
    type: REMOVE_MEDIA,
    reducer: state =>
      state.map(post => {
        if (post.id === postId) {
          const nextPost = {
            ...post,
            media: post.media.filter(item => item.id !== itemId)
          };

          return nextPost;
        }

        return post;
      })
  };
}

export function removeMediaItem(postId: number, itemId: number): ThunkAction {
  return dispatch => {
    dispatch(showSpinner());

    const endpoint = `${ENDPOINT_MEDIA}/${itemId}`;

    return axios
      .delete(endpoint)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          dispatch(_removeMediaItem(postId, itemId));
          dispatch(showAlert('El adjunto se borró con éxito!', 'success'));
        } else {
          dispatch(showAlert('Error al borrar el adjunto', 'error'));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}
