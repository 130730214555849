// @flow
import React, { Fragment } from 'react';
import Table from '../Table/Table';
import Button from '../Button/Button';
import ColorPreview from '../ColorPreview/ColorPreview';
import Badge from '../Badge/Badge';
import Center from '../Center/Center';
import type { PostType } from '../../types';
import './PostsList.css';

export type Props = {
  posts: PostType[],
  onClick: () => void
};

export default function PostsList(props: Props) {
  const rows = props.posts.map(post => ({
    link: `/posts/${post.id}`,
    cells: [
      post.title,
      <Center>
        <ColorPreview color={post.category.color} title={post.category.name} />
      </Center>,
      <Center>
        <Badge>{post.date}</Badge>
      </Center>
    ]
  }));

  return (
    <Fragment>
      <Table header={['Título', <Center>Categoría</Center>, <Center>Fecha</Center>]} rows={rows} />

      <div className="Posts-List__button-wrapper">
        <Button className="Posts-List__button" type="primary" onClick={props.onClick}>
          Cargar más noticias
        </Button>
      </div>
    </Fragment>
  );
}
