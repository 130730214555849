// @flow
import React from 'react';
import { TwitterPicker } from 'react-color';
import Button from '../Button/Button';
import type { ColorType } from '../../types';
import './CategoryForm.css';

type Props = {
  id?: number,
  name?: string,
  description?: string,
  color?: string,
  posts?: number,
  onSubmit: (name: string, description: string, color: string) => void
};

type State = {
  name: string,
  description: string,
  color: string
};

export default class CategoryForm extends React.Component<Props, State> {
  state = {
    name: this.props.name || '',
    description: this.props.description || '',
    color: this.props.color || '#abb8c3'
  };

  componentWillReceiveProps(nextProps: Props) {
    // We only evaluate the category name here
    // as the description and color are both nullable values.
    const receivedProps = nextProps.name;

    if (receivedProps) {
      this.setState({
        name: nextProps.name,
        description: nextProps.description,
        color: nextProps.color
      });
    }
  }

  onNameChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      name: event.target.value
    });
  };

  onDescriptionChange = (event: SyntheticInputEvent<HTMLTextAreaElement>) => {
    this.setState({
      description: event.target.value
    });
  };

  onColorChange = (color: ColorType) => {
    this.setState({ color: color.hex });
  };

  onSubmit = () => {
    this.props.onSubmit(
      this.state.name,
      this.state.description,
      this.state.color
    );
  };

  render() {
    return (
      <form method="POST">
        <ul className="List-Form__fields">
          <li className="List-Form__field">
            <label htmlFor="name" className="List-Form__label">
              Nombre
            </label>
            <input
              id="name"
              type="text"
              value={this.state.name}
              className="List-Form__input"
              placeholder="Nombre de la nueva categoría"
              onChange={this.onNameChange}
            />
          </li>

          <li className="List-Form__field">
            <label htmlFor="description" className="List-Form__label">
              Descripción
            </label>
            <textarea
              id="description"
              className="List-Form__textarea"
              placeholder="Breve descripción sobre la temática de las noticias que van a ser parte de esta categoría"
              value={this.state.description}
              onChange={this.onDescriptionChange}
            />
          </li>

          <li className="List-Form__field">
            <label className="List-Form__label">Color</label>
            <div className="Category-Form__color">
              <TwitterPicker
                triangle="hide"
                color={this.state.color}
                onChangeComplete={this.onColorChange}
              />
              <span
                className="Category-Form__color-preview"
                style={{ backgroundColor: this.state.color }}
              />
            </div>
          </li>

          <li className="List-Form__field">
            <Button type="confirm" onClick={this.onSubmit}>
              Guardar
            </Button>

            {this.props.id &&
              this.props.posts !== undefined && (
                <Button
                  className="Category-Form__remove-button"
                  type="danger"
                  disabled={this.props.posts > 0}
                  to={`/categories/${this.props.id}/remove`}
                >
                  Borrar
                </Button>
              )}
          </li>
        </ul>
      </form>
    );
  }
}
