// @flow
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import find from 'lodash/find';
import PostPreview from '../components/PostPreview/PostPreview';
import type { Match } from 'react-router-dom';
import type { PostType } from '../types';

type Props = {
  posts: PostType[],
  match: Match // Provided by withRouter
};

function PostDetailsContainer(props: Props) {
  const id = Number(props.match.params.id);
  const post = find(props.posts, { id });

  return <PostPreview {...post} />;
}

function mapStateToProps(state) {
  return {
    posts: state.posts
  };
}

export default withRouter(connect(mapStateToProps)(PostDetailsContainer));
