// @flow
import React, { Fragment } from 'react';
import Button from '../components/Button/Button';
import Heading from '../components/Heading/Heading';
import Box from '../components/Box/Box';
import ObituariesListContainer from '../containers/ObituariesList';

const createObituaryButton = (
  <Button type="confirm" to="obituaries/create">
    Nuevo Aviso
  </Button>
);

export default function ObituariesListRoute() {
  return (
    <Fragment>
      <Heading cta={createObituaryButton}>Avisos Necrológicos</Heading>
      <Box fullWidth>
        <ObituariesListContainer />
      </Box>
    </Fragment>
  );
}
