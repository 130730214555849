// @flow
import axios from 'axios';
import { push } from 'react-router-redux';
import { showSpinner, hideSpinner } from './spinner';
import { showAlert } from './alerts';
import { RECEIVE_PAGES } from './action-types';
import { ENDPOINT_PAGES, INTERNAL_SERVER_ERROR } from '../utils/constants';
import { parseDateFromApi } from '../utils/dates';
import type { PageType, Action, ThunkAction } from '../types';

function receivePages(pages: PageType[]): Action {
  return {
    type: RECEIVE_PAGES,
    reducer: () => pages
  };
}

export function getPages(): ThunkAction {
  return dispatch => {
    dispatch(showSpinner());

    axios
      .get(ENDPOINT_PAGES)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          const pages = response.data.data.map(page => ({
            id: page.id,
            title: page.title,
            slug: page.slug,
            body: page.body,
            date: parseDateFromApi(page.updated_at)
          }));

          dispatch(receivePages(pages));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}

export function updatePage(data: PageType): ThunkAction {
  return (dispatch, getState) => {
    dispatch(showSpinner());

    const endpoint = `${ENDPOINT_PAGES}/${data.id}`;
    const body = {
      title: data.title,
      slug: data.slug,
      body: data.body
    };

    axios
      .put(endpoint, body)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          dispatch(showAlert('La página se actualizó con éxito!', 'success'));
          dispatch(getPages());
          dispatch(push('/pages'));
        } else {
          dispatch(showAlert('Error al actualizar la página', 'error'));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}

export function createPage(data: PageType): ThunkAction {
  return (dispatch, getState) => {
    dispatch(showSpinner());

    const body = {
      title: data.title,
      slug: data.slug,
      body: data.body
    };

    axios
      .post(ENDPOINT_PAGES, body)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          dispatch(showAlert('La página se creó con éxito!', 'success'));
          dispatch(getPages());
          dispatch(push('/pages'));
        } else {
          dispatch(showAlert('Error al crear la página', 'error'));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}

export function removePage(id: number): ThunkAction {
  return (dispatch, getState) => {
    dispatch(showSpinner());

    const endpoint = `${ENDPOINT_PAGES}/${id}`;

    axios
      .delete(endpoint)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          dispatch(showAlert('La página se borró con éxito!', 'success'));
          dispatch(getPages());
          dispatch(push('/pages'));
        } else {
          dispatch(showAlert('Error al borrar la página', 'error'));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}
