// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, type Match } from 'react-router-dom';
import find from 'lodash/find';
import { savePost, removeMediaItem } from '../state/posts';
import PostForm from '../components/PostForm/PostForm';
import type { CategoryType, PostFormType, PostType } from '../types';

type Props = {
  title: string,
  excerpt: string,
  body: string,
  selectedCategory: CategoryType,
  categories: CategoryType[],
  posts: PostType[],
  match: Match, // Provided by withRouter
  onSubmit: (post: PostFormType) => void,
  onRemoveMediaItem: (postId: number, itemId: number) => void
};

function PostUpdateContainer(props: Props) {
  const id = Number(props.match.params.id);
  const post = find(props.posts, { id });

  return (
    <PostForm
      {...post}
      categories={props.categories}
      onSubmit={props.onSubmit}
      onRemoveMediaItem={props.onRemoveMediaItem}
    />
  );
}

function mapStateToProps(state) {
  return {
    posts: state.posts,
    categories: state.categories
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { onSubmit: savePost, onRemoveMediaItem: removeMediaItem },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PostUpdateContainer)
);
