// @flow
import React from 'react';
import Table from '../Table/Table';
import Badge from '../Badge/Badge';
import Center from '../Center/Center';
import type { MessageType } from '../../types';

export type Props = {
  messages: MessageType[]
};

export default function MessagesList(props: Props) {
  const rows = props.messages.map(message => ({
    link: `/messages/${message.id}`,
    cells: [
      message.subject,
      <Center>
        <Badge>{message.date}</Badge>
      </Center>
    ]
  }));

  return <Table header={['Asunto', <Center>Fecha</Center>]} rows={rows} />;
}
