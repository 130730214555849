// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';
import find from 'lodash/find';
import get from 'lodash/get';
import { removeCategory } from '../state/categories';
import ConfirmRemove from '../components/ConfirmRemove/ConfirmRemove';
import type { Match } from 'react-router-dom';
import type { CategoryType } from '../types';

type Props = {
  categories: CategoryType[],
  match: Match, // Provided by withRouter
  push: (path: string) => void,
  onRemove: (id: number) => void
};

function CategoryRemoveContainer(props: Props) {
  const id = Number(props.match.params.id);
  const page = find(props.categories, { id });
  const name = get(page, 'name');

  return (
    <ConfirmRemove
      onBack={() => props.push(`/categories/${id}/edit`)}
      onRemove={() => props.onRemove(id)}
    >
      Categoría: <strong>{name}</strong>
    </ConfirmRemove>
  );
}

function mapStateToProps(state) {
  return {
    categories: state.categories
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onRemove: removeCategory, push }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CategoryRemoveContainer)
);
