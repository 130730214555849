// @flow
import React from 'react';
import Editor from '../Editor/Editor';
import Button from '../Button/Button';
import Badge from '../Badge/Badge';
import './PagePreview.css';

type Props = {
  slug: string,
  title: string,
  body: string,
  date: string
};

export default function PagePreview(props: Props) {
  return (
    <article>
      <header>
        <h1 className="Page-Preview__title">{props.title}</h1>
        <ul className="Page-Preview__list">
          <li>
            <Badge className="Page-Preview__badge">{`Última Actualización: ${
              props.date
            }`}</Badge>
            <Badge className="Page-Preview__badge">{`Slug: ${
              props.slug
            }`}</Badge>
          </li>
          <li>
            <Button
              type="primary"
              size="small"
              className="Page-Preview__button"
              to={`/pages/${props.slug}/edit`}
            >
              Modificar
            </Button>

            <Button
              type="danger"
              size="small"
              className="Page-Preview__button"
              to={`/pages/${props.slug}/remove`}
            >
              Borrar
            </Button>
          </li>
        </ul>
      </header>

      <Editor readOnly>{props.body}</Editor>
    </article>
  );
}
