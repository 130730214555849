// @flow
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import find from 'lodash/find';
import MessagePreview from '../components/MessagePreview/MessagePreview';
import type { Match } from 'react-router-dom';
import type { MessageType } from '../types';

type Props = {
  messages: MessageType[],
  match: Match // Provided by withRouter
};

function MessageDetailsContainer(props: Props) {
  const id = Number(props.match.params.id);
  const message = find(props.messages, { id });

  return <MessagePreview {...message} />;
}

function mapStateToProps(state) {
  return {
    messages: state.messages
  };
}

export default withRouter(connect(mapStateToProps)(MessageDetailsContainer));
