// @flow
import React from 'react';
import RichTextEditor, {
  createValueFromString,
  createEmptyValue
} from 'react-rte';
import TOOLBAR_CONFIG from './Editor.config';

type Props = {
  children?: string,
  placeholder?: string,
  readOnly: boolean,
  simple: boolean
};

type State = {
  value: string,
  initialized: boolean
};

export default class Editor extends React.Component<Props, State> {
  state = {
    value: !!this.props.children
      ? createValueFromString(this.props.children, 'html')
      : createEmptyValue(),
    initialized: false
  };

  static defaultProps = {
    readOnly: false,
    simple: false
  };

  componentWillReceiveProps(nextProps: Props) {
    const initialValue = nextProps.children;
    const initialized = this.state.initialized;
    const shouldUpdateContent = !!initialValue && !initialized;

    if (shouldUpdateContent) {
      this.setState({
        value: createValueFromString(initialValue, 'html'),
        initialized: true
      });
    }
  }

  onChange = (value: string) => {
    this.setState({
      value
    });
  };

  render() {
    const { readOnly, placeholder } = this.props;
    const { value } = this.state;
    const toolbarConfig = this.props.simple ? TOOLBAR_CONFIG : null;

    return (
      <RichTextEditor
        value={value}
        toolbarConfig={toolbarConfig}
        readOnly={readOnly}
        placeholder={placeholder}
        onChange={this.onChange}
      />
    );
  }
}
