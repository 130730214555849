// @flow
import axios from 'axios';
import get from 'lodash/get';
import { push } from 'react-router-redux';
import { showSpinner, hideSpinner } from './spinner';
import { showAlert } from './alerts';
import { uploadImages } from './imageUpload';
import { isMockImageFile } from '../utils/helpers';
import { RECEIVE_ADS, FILTER_ADS } from './action-types';
import { ENDPOINT_ADS, INTERNAL_SERVER_ERROR } from '../utils/constants';
import type { AdType, FilterType, ImageType, Action, ThunkAction } from '../types';

function receiveAds(ads: AdType[]): Action {
  return {
    type: RECEIVE_ADS,
    reducer: () => ads
  };
}

function receiveAdsFilter(filter: FilterType): Action {
  return {
    type: FILTER_ADS,
    reducer: () => filter
  };
}

export function toggleAdsFilter(): ThunkAction {
  return (dispatch, getState) => {
    const filter = getState().adsFilter === 'all' ? 'active' : 'all';

    dispatch(receiveAdsFilter(filter));
    dispatch(getAds());
  };
}

export function getAds(): ThunkAction {
  return (dispatch, getState) => {
    dispatch(showSpinner());

    const endpoint = getState().adsFilter === 'active' ? ENDPOINT_ADS : `${ENDPOINT_ADS}/?all`;

    axios
      .get(endpoint)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          const ads = response.data.data.map(ad => ({
            id: ad.id,
            title: ad.title,
            type: ad.type,
            url: ad.website_url,
            imageUrl: ad.image_url,
            from: ad.from,
            to: ad.to
          }));

          dispatch(receiveAds(ads));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}

export function createAd(
  title: string,
  typeId: number,
  url: string,
  images: ImageType[],
  from: string,
  to: string
): ThunkAction {
  return dispatch => {
    // When invoking a Redux Thunk, `dispatch` returns whatever value you are
    // returning from your Thunk action creator, in this case, a promise.
    const uploadImagePromise = dispatch(uploadImages(images));

    // Returning a promise from a Thunk means we can act when it's resolved.
    // Here we are waiting until all images (in this case, a single one)
    // finishes uploading. If no image gets queued up to upload, then our
    // promise resolves immediately and the processing continues its course.
    uploadImagePromise.then(urls => {
      dispatch(showSpinner());

      const payload = {
        title,
        type_id: typeId,
        website_url: url,
        image_url: get(urls, '[0]', null), // Retrieve URL returned by the CDN.
        from,
        to
      };

      axios
        .post(ENDPOINT_ADS, payload)
        .then(response => {
          dispatch(hideSpinner());

          if (response.status === 200) {
            dispatch(showAlert('Publicidad creada con éxito!', 'success'));
            dispatch(getAds());
            dispatch(push('/ads'));
          } else {
            dispatch(showAlert('Error al intentar crear la publicidad', 'error'));
          }
        })
        .catch((error: TypeError) => {
          dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
          dispatch(hideSpinner());
        });
    });
  };
}

export function updateAd(
  id: number,
  title: string,
  typeId: number,
  url: string,
  images: ImageType[],
  from: string,
  to: string
): ThunkAction {
  return (dispatch, getState) => {
    // Mocked images are those we create from an URL, and we only upload an
    // image if the user has selected it from the file picker (i.e. not a mock).
    const imageToUpload = images.filter(image => !isMockImageFile(image));
    const uploadImagePromise = dispatch(uploadImages(imageToUpload));

    uploadImagePromise.then(urls => {
      dispatch(showSpinner());

      // Retrieve the URL to the original Ad image. The `preview`
      // property of a mock image contains the URL to the picture on the CDN.
      const originalImage = get(images, '[0].preview');

      // If a new image has been uploaded,
      // it will override the previous Ad image, if any.
      const newImageHasBeenUploaded = imageToUpload.length === 1;

      // Determine if the user has left the original Ad image untouched.
      const shouldKeepOriginalImage = imageToUpload.length === 0 && !!originalImage;

      // Here we handle three different scenarios:
      // 1. We've got a new picture.
      // 2. We keep the original picture.
      // 3. We save the Ad with no picture attached.
      const nextImageUrl = newImageHasBeenUploaded
        ? get(urls, '[0]') // Get image url from CDN.
        : shouldKeepOriginalImage ? originalImage : null; // null means no image.

      // Finally, hit the API.
      const endpoint = `${ENDPOINT_ADS}/${id}`;
      const payload = {
        title,
        type_id: typeId,
        website_url: url,
        image_url: nextImageUrl,
        from,
        to
      };

      axios
        .put(endpoint, payload)
        .then(response => {
          dispatch(hideSpinner());

          if (response.status === 200) {
            dispatch(showAlert('Publicidad actualizada con éxito!', 'success'));
            dispatch(getAds());
            dispatch(push('/ads'));
          } else {
            dispatch(showAlert('Error al actualizar la publicidad', 'error'));
          }
        })
        .catch((error: TypeError) => {
          dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
          dispatch(hideSpinner());
        });
    });
  };
}

export function removeAd(id: number): ThunkAction {
  return dispatch => {
    dispatch(showSpinner());

    const endpoint = `${ENDPOINT_ADS}/${id}`;

    axios
      .delete(endpoint)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          dispatch(showAlert('La publicidad se borró con éxito!', 'success'));
          dispatch(getAds());
          dispatch(push('/ads'));
        } else {
          dispatch(showAlert('Error al borrar la publicidad', 'error'));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}
