// @flow
import * as React from 'react';
import classnames from 'classnames';
import Navigation from '../../components/Navigation/Navigation';
import Alert from '../../components/Alert/Alert';
import Spinner from '../../components/Spinner/Spinner';
import Progress from '../../components/Progress/Progress';
import type { AlertType } from '../../types';
import './App.css';

export type Props = {
  children?: React.Node,
  authenticated: boolean,
  loading: boolean,
  progress: number,
  alerts: AlertType[],
  hideAlert: (id: string) => void,
  onLogout: () => void
};

export default function App(props: Props) {
  const className = classnames('App', {
    'App--authenticated': props.authenticated
  });

  return (
    <React.Fragment>
      {/* Display navigation menu only to authenticated users */}
      {props.authenticated && <Navigation onLogout={props.onLogout} />}

      <div className={className}>{props.children}</div>

      {props.loading && <Spinner />}

      {props.progress > 0 && <Progress total={props.progress} />}

      {props.alerts.length > 0 && (
        <div className="App__alerts">
          {props.alerts.map(alert => (
            <Alert key={alert.id} type={alert.type} onClose={() => props.hideAlert(alert.id)}>
              {alert.message}
            </Alert>
          ))}
        </div>
      )}
    </React.Fragment>
  );
}

App.defaultProps = {
  alerts: [],
  authenticated: false,
  loading: false,
  progress: null
};
