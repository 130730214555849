// @flow
import * as React from 'react';
import Button from '../Button/Button';
import warningIcon from './warning.svg';
import './ConfirmRemove.css';

type Props = {
  children: string | React.Node,
  onBack: () => void,
  onRemove: () => void
};

export default function Remove(props: Props) {
  return (
    <div className="Confirm-Remove">
      <img src={warningIcon} alt="Alerta" className="Confirm-Remove__warning" />

      <p className="Confirm-Remove__title">
        Está seguro que quiere borrar este elemento?
      </p>

      <span className="Confirm-Remove__details">{props.children}</span>

      <p className="Confirm-Remove__blurb">
        Tenga en cuenta que esta acción no puede deshacerse.
      </p>

      <div className="Confirm-Remove__controls">
        <Button onClick={props.onBack}>Cancelar</Button>

        <Button
          className="Confirm-Remove__remove-button"
          type="danger"
          onClick={props.onRemove}
        >
          Eliminar
        </Button>
      </div>
    </div>
  );
}
