// @flow
import axios from 'axios';
import { push } from 'react-router-redux';
import { showSpinner, hideSpinner } from './spinner';
import { showAlert } from './alerts';
import { RECEIVE_ROSTER } from './action-types';
import { ENDPOINT_ROSTER, INTERNAL_SERVER_ERROR } from '../utils/constants';
import { parseDateFromApi } from '../utils/dates';
import type { RosterType, Action, ThunkAction } from '../types';

function receiveRoster(entries: RosterType[]): Action {
  return {
    type: RECEIVE_ROSTER,
    reducer: () => entries
  };
}

export function getRoster(): ThunkAction {
  return dispatch => {
    dispatch(showSpinner());

    axios
      .get(ENDPOINT_ROSTER)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          const roster = response.data.data.map(entry => ({
            id: entry.id,
            activity: entry.activity,
            name: entry.name,
            phone: entry.phone,
            outdated: entry.outdated,
            date: parseDateFromApi(entry.updated_at)
          }));
          dispatch(receiveRoster(roster));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}

export function createRoster(
  activity: string,
  name: string,
  phone: string
): ThunkAction {
  return dispatch => {
    dispatch(showSpinner());

    const body = {
      activity,
      name,
      phone
    };

    axios
      .post(ENDPOINT_ROSTER, body)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          dispatch(getRoster());
          dispatch(showAlert('El turno se creó con éxito!', 'success'));
          dispatch(push('/roster'));
        } else {
          dispatch(showAlert('Error al intentar crear el turno', 'error'));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}

export function updateRoster(
  id: number,
  activity: string,
  name: string,
  phone: string
): ThunkAction {
  return dispatch => {
    dispatch(showSpinner());

    const endpoint = `${ENDPOINT_ROSTER}/${id}`;
    const body = {
      activity,
      name,
      phone
    };

    axios
      .put(endpoint, body)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          dispatch(getRoster());
          dispatch(showAlert('El turno se actualizó con éxito!', 'success'));
        } else {
          dispatch(showAlert('Error al intentar actualizar el turno', 'error'));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}

export function removeRoster(id: number): ThunkAction {
  return dispatch => {
    dispatch(showSpinner());

    axios
      .delete(`${ENDPOINT_ROSTER}/${id}`)
      .then(response => {
        dispatch(hideSpinner());

        if (response.status === 200) {
          dispatch(getRoster());
          dispatch(showAlert('El turno se eliminó con éxito!', 'success'));
          dispatch(push('/roster'));
        } else {
          dispatch(showAlert('Error al intentar eliminar el turno', 'error'));
        }
      })
      .catch((error: TypeError) => {
        dispatch(showAlert(INTERNAL_SERVER_ERROR, 'error'));
        dispatch(hideSpinner());
      });
  };
}
