// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import type { State } from '../types';

// Categories
import CategoriesList from '../routes/CategoriesList';
import CategoryCreate from '../routes/CategoryCreate';
import CategoryUpdate from '../routes/CategoryUpdate';
import CategoryRemove from '../routes/CategoryRemove';

// Roster
import RosterList from '../routes/RosterList';
import RosterCreate from '../routes/RosterCreate';
import RosterRemove from '../routes/RosterRemove';

// Posts
import PostsList from '../routes/PostsList';
import PostDetails from '../routes/PostDetails';
import PostCreate from '../routes/PostCreate';
import PostUpdate from '../routes/PostUpdate';
import PostRemove from '../routes/PostRemove';

// Pages
import PagesList from '../routes/PagesList';
import PageDetails from '../routes/PageDetails';
import PageCreate from '../routes/PageCreate';
import PageUpdate from '../routes/PageUpdate';
import PageRemove from '../routes/PageRemove';

// Obituaries
import ObituariesList from '../routes/ObituariesList';
import ObituaryCreate from '../routes/ObituaryCreate';
import ObituaryUpdate from '../routes/ObituaryUpdate';
import ObituaryRemove from '../routes/ObituaryRemove';

// Classifieds
import ClassifiedsList from '../routes/ClassifiedsList';
import ClassifiedCreate from '../routes/ClassifiedCreate';
import ClassifiedUpdate from '../routes/ClassifiedUpdate';
import ClassifiedRemove from '../routes/ClassifiedRemove';

// Ads
import AdList from '../routes/AdList';
import AdCreate from '../routes/AdCreate';
import AdUpdate from '../routes/AdUpdate';
import AdRemove from '../routes/AdRemove';

// Messages
import MessagesList from '../routes/MessagesList';
import MessageDetails from '../routes/MessageDetails';

// Pharmacy Roster
import PharmacyRosterDetails from '../routes/PharmacyRosterDetails';
import PharmacyExceptionCreate from '../routes/PharmacyExceptionCreate';

// Login
import Login from '../routes/Login';

// 404 Not Found
import NoMatch from '../components/NoMatch/NoMatch';

type Props = {
  authenticated: boolean
};

function RoutesContainer(props: Props) {
  return (
    <Fragment>
      {props.authenticated && (
        <Switch>
          <Route exact path="/categories" component={CategoriesList} />
          <Route exact path="/categories/create" component={CategoryCreate} />
          <Route exact path="/categories/:id/edit" component={CategoryUpdate} />
          <Route exact path="/categories/:id/remove" component={CategoryRemove} />

          <Route exact path="/posts" component={PostsList} />
          <Route exact path="/posts/create" component={PostCreate} />
          <Route exact path="/posts/:id" component={PostDetails} />
          <Route exact path="/posts/:id/edit" component={PostUpdate} />
          <Route exact path="/posts/:id/remove" component={PostRemove} />

          <Route exact path="/pages" component={PagesList} />
          <Route exact path="/pages/create" component={PageCreate} />
          <Route exact path="/pages/:slug" component={PageDetails} />
          <Route exact path="/pages/:slug/edit" component={PageUpdate} />
          <Route exact path="/pages/:slug/remove" component={PageRemove} />

          <Route exact path="/roster" component={RosterList} />
          <Route exact path="/roster/create" component={RosterCreate} />
          <Route exact path="/roster/:id/remove" component={RosterRemove} />

          <Route exact path="/obituaries" component={ObituariesList} />
          <Route exact path="/obituaries/create" component={ObituaryCreate} />
          <Route exact path="/obituaries/:id/edit" component={ObituaryUpdate} />
          <Route exact path="/obituaries/:id/remove" component={ObituaryRemove} />

          <Route exact path="/classifieds" component={ClassifiedsList} />
          <Route exact path="/classifieds/create" component={ClassifiedCreate} />
          <Route exact path="/classifieds/:id/edit" component={ClassifiedUpdate} />
          <Route exact path="/classifieds/:id/remove" component={ClassifiedRemove} />

          <Route exact path="/ads" component={AdList} />
          <Route exact path="/ads/create" component={AdCreate} />
          <Route exact path="/ads/:id/remove" component={AdRemove} />
          <Route exact path="/ads/:id/edit" component={AdUpdate} />

          <Route exact path="/messages" component={MessagesList} />
          <Route exact path="/messages/:id" component={MessageDetails} />

          <Route exact path="/pharmacies" component={PharmacyRosterDetails} />
          <Route exact path="/pharmacies/exceptions/create" component={PharmacyExceptionCreate} />

          {/* Start off with the Posts page  */}
          <Redirect exact from="/" to="/posts" />

          {/* 404 Page */}
          <Route component={NoMatch} />
        </Switch>
      )}

      {!props.authenticated && (
        <Switch>
          <Route exact path="/login" component={Login} />
          <Redirect from="*" to="/login" />
        </Switch>
      )}
    </Fragment>
  );
}

function mapStateToProps(state: State) {
  return {
    authenticated: !!state.token
  };
}

export default withRouter(connect(mapStateToProps)(RoutesContainer));
