// @flow
import React from 'react';
import Editor from '../Editor/Editor';
import './MessagePreview.css';
import type { MessageType } from '../../types';

type Props = MessageType;

export default function MessagePreview(props: Props) {
  return (
    <div>
      <ul className="Message-Preview__list">
        <li className="Message-Preview__list-item">
          De: <a href={`mailto:${props.from}`}>{props.from}</a>
        </li>
        <li className="Message-Preview__list-item">
          Para: <a href={`mailto:${props.to}`}>{props.to}</a>
        </li>
        <li className="Message-Preview__list-item">
          Asunto: <strong>{props.subject}</strong>
        </li>
        <li className="Message-Preview__list-item">
          Fecha: <strong>{props.date}</strong>
        </li>
      </ul>

      <Editor readOnly>{props.body}</Editor>
    </div>
  );
}
