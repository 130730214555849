// @flow
function isCodeOptimized(): boolean {
  return process.env.NODE_ENV === 'production';
}

export function isProductionEnvironment(): boolean {
  return isCodeOptimized() && process.env.REACT_APP_ENV === 'production';
}

function isStagingEnvironment(): boolean {
  return isCodeOptimized() && process.env.REACT_APP_ENV === 'staging';
}

function isDevelopmentEnvironment(): boolean {
  return process.env.NODE_ENV === 'development';
}

export function getEnvironment(): string {
  if (isProductionEnvironment()) {
    return 'production';
  } else if (isStagingEnvironment()) {
    return 'staging';
  } else if (isDevelopmentEnvironment()) {
    return 'development';
  } else {
    throw Error('Invalid environment');
  }
}
