// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import find from 'lodash/find';
import { updateObituary } from '../state/obituaries';
import ObituaryForm from '../components/ObituaryForm/ObituaryForm';
import type { Match } from 'react-router-dom';
import type { ObituaryType } from '../types';

type Props = {
  obituaries: ObituaryType[],
  match: Match, // Provided by withRouter
  onSubmit: (
    id: number,
    name: string,
    details: string,
    service: string,
    church: string,
    cemetery: string,
    home: string
  ) => void
};

function ObituaryUpdateContainer(props: Props) {
  const id = Number(props.match.params.id);
  const obituary = find(props.obituaries, { id });
  const onSubmit = (name, details, service, church, cemetery, home) =>
    props.onSubmit(id, name, details, service, church, cemetery, home);

  return <ObituaryForm {...obituary} onSubmit={onSubmit} />;
}

function mapStateToProps(state) {
  return {
    obituaries: state.obituaries
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onSubmit: updateObituary }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ObituaryUpdateContainer)
);
